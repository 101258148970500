import { ThemeProvider } from '@mui/material';
import {BrowserRouter, useLocation} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from "react-query";
import ErrorBoundary   from 'shared/ErrorBoundary';
import theme from 'shared/theme';
import 'styles/index.scss';
import 'styles/community.scss';
import {LicenseInfo} from "@mui/x-license-pro";
import { GlobalModals } from "./pages/store/GlobalModals";
import { useGlobalModalStore } from './pages/store/GlobalModalStore';
import Layout from "shared/layout";
import {DynamicRouter} from "shared/DynamicRouter";
import {MainRoute} from "~/routes/MainRoute";
import {SearchRoute} from "~/routes/SearchRoute";
import {SignupRoute} from "~/routes/SignupRoute";
import {SignInRoute} from "~/routes/SignInRoute";
import {DetailPagesRoutes, UtilityRoutes} from "~/routes/DetailPageRoute";
import {useCallback, useEffect} from "react";
import {useRouteStore} from "shared/store/RouteConfigStore";
import {useGlobalConfigStore} from "shared/store/GlobalConfigStore";
import {setup} from "shared/libs/axios";
LicenseInfo.setLicenseKey(`${process.env.REACT_APP_X_LICENSE_PRO}`);
export const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // refetchOnReconnect: false,
      // refetchOnMount: false,
      refetchOnWindowFocus: false,
      retryOnMount:false,
      retry: false,
      // onError: (err: any) =>{
      //   console.log(err)
      // }
    }
  }
});

// react query config default ..
const App = () => {
  const routeStore = useRouteStore()
  const configStore = useGlobalConfigStore()

  const handlerResize = useCallback((e) => {
    configStore.setDevice(isMobileDevice()? 'mobile' : 'pc')
    const isDesktop = isMobileDevice()? window.screen.width> 768 : window.innerWidth > 768
    if (configStore.isDesktop != isDesktop)
      configStore.setScreenMode(isDesktop)
  }, [window.innerWidth])

  useEffect(() => {
    window.addEventListener("resize", handlerResize)
    return () => {
      window.removeEventListener("resize", handlerResize)
    }
  }, [handlerResize])

  useEffect(() => {
    routeStore.setLayout('empty')
    routeStore.setDefaultLayout('empty')
    if (process.env.REACT_APP_MODE == 'prd'){
      console.log = () => {}
      console.warn = () => {}
      console.error = () => {}
    }
  }, [])

  useEffect(() => {
    const domain = process.env.REACT_APP_DOMAIN
    if (domain) {
      setup({
        baseURL: `${window.location.protocol}//${domain.split('//')[1]}`
      })
    }
  }, [window.location.protocol])

  return (
   <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Layout>
              <DynamicRouter
                portalType={routeStore.portal}
                addRoutes={[
                  ...MainRoute,
                  ...SearchRoute,
                  ...SignupRoute,
                  ...SignInRoute,
                  ...UtilityRoutes,
                  ...DetailPagesRoutes,
                ]}
              />
            </Layout>
          </BrowserRouter>
          <GlobalModals/>
        </ThemeProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
