import {Fragment, useEffect} from 'react';
import styled from "@emotion/styled";
import {Color} from "../../components/StyleUtils";
import {NavLink} from "react-router-dom";
import * as React from "react";
import {css} from "@emotion/react";

type PropsType = {
  children?: any;
};

function Space({ children }: PropsType) {
  const init = () => {
    document.querySelector('body')!.classList.add('layout--space');
    return () => {
      document.querySelector('body')!.classList.remove('layout--space');
    };
  };
  useEffect(init, []);

  return <Fragment>
    <HeaderLogo>
      <NavLink to={'/'}>
        <ScreenOut>ATOPS 인공지능산업융합사업단</ScreenOut>
      </NavLink>
    </HeaderLogo>
    {children}
  </Fragment>;
}

export const ScreenOut = styled('span')`
  position: absolute;
  width: 0;
  height: 0;
  line-height: 0;
  overflow: hidden;
  text-indent: -9999px;
`;

const HeaderLogo = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  background: ${Color.darkbg};
  height: 80px;
  padding-left: 40px;
  box-shadow: inset 0 -1px 0 0 rgba(204, 204, 204, 0.2);
  transition: height 0.3s;

  a {
    width: 240px;
    height: 23px;
    background: url('/images/white_logo.png') no-repeat center / contain;
    transition: height 0.3s, opacity 0.3s;
  }

  .is-not-scroll & {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: ${Color.darkbg};
    z-index: 300;
  }

  @media (max-width: '1200px') {
    justify-content: center;
    height: 60px;
    padding-left: 0;

    a {
      width: 90px;
      height: 34px;
      background-image: url('/images/logo-wathermark-white.svg');
      background-size: 90px 34px;
    }

    .is-scroll-dw & {
      height: 0;

      a {
        height: 0;
        opacity: 0;
      }
    }

    .is-scroll-up & {
      height: 60px;
    }
  }
`;

export default Space;
