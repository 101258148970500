// 로그인 라우터
import React from "react";
import {Loader, RouteType} from "shared/utils/RouteUtiles";

export const SignInRoute: RouteType[] = [
  {
    path: 'signin',
    children: [
      {
        index: true,
        element: (
          <Loader
            route={{
              label: 'sign',
              layout: 'space',
              element: React.lazy(() => import('~/pages/SignIn')),
            }}
          />
        ),
      },
      {
        path: 'bridge',
        label: 'sso로그인',
        element: (
          <Loader
            route={{
              label: 'signBridge',
              layout: 'empty',
              element: React.lazy(
                () => import('~/pages/SignIn/SignInBridge')
              ),
            }}
          />
        ),
      },
      {
        path: 'dormancyPass',
        label: '비밀번호 변경 안내',
        element: (
          <Loader
            route={{
              label: 'sign',
              layout: 'space',
              element: React.lazy(
                () => import('~/pages/SignIn/dormancy/DormancyPass')
              ),
            }}
          />
        ),
      },
      {
        path: 'idtrouver',
        label: '아이디 찾기',
        element: (
          <Loader
            route={{
              label: 'sign',
              layout: 'space',
              element: React.lazy(() => import('~/pages/SignIn/IdTrouver')),
            }}
          />
        ),
      },
      {
        path: 'idTrouverFind',
        label: '아이디 확인',
        element: (
          <Loader
            route={{
              label: 'sign',
              layout: 'space',
              element: React.lazy(() => import('~/pages/SignIn/IdTrouverFind')),
            }}
          />
        ),
      },
      {
        path: 'signout',
        label: '로그아웃',
        element: (
          <Loader
            route={{
              label: 'signout',
              layout: 'empty',
              element: React.lazy(() => import('~/pages/SignOut')),
            }}
          />
        ),
      },
      {
        path: 'factorfind',
        label: '비밀번호 확인 (본인인증)',
        element: (
          <Loader
            route={{
              label: 'sign',
              layout: 'space',
              element: React.lazy(() => import('~/pages/SignIn/FactorFind')),
            }}
          />
        ),
      },
      {
        path: 'factorreset',
        label: '비밀번호 확인 (재설정)',
        element: (
          <Loader
            route={{
              label: 'sign',
              layout: 'space',
              element: React.lazy(() => import('~/pages/SignIn/FactorReset')),
            }}
          />
        ),
      },
      {
        path: 'Factor',
        label: '비밀번호 재설정',
        element: (
          <Loader
            route={{
              label: 'space',
              layout: 'space',
              element: React.lazy(() => import('~/pages/SignIn/Factor')),
            }}
          />
        ),
      },
      {
        path: 'snsNaverCallback',
        label: '컴포넌트 샘플',
        element: (
          <Loader
            route={{
              label: 'sns-login',
              layout: 'empty',
              element: React.lazy(() => import('~/pages/SnsNaverCallback')),
            }}
          />
        ),
      },
      {
        path: 'snsNaverConfigCallback',
        label: '네이버 로그인 설정 콜백',
        element: (
          <Loader
            route={{
              label: 'home',
              layout: 'empty',
              element: React.lazy(
                () => import('~/pages/SnsNaverCallback/SnsNaverConfigCallback')
              ),
            }}
          />
        ),
      },
    ],
  },
].map((route: Partial<RouteType>) => ({
  ...route,
  layout: 'empty',
})) as RouteType[];