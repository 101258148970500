import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import {HorizontalInterval, Pagination, VerticalInterval, WordCount} from "../components/LayoutComponents";
import {useEffect, useLayoutEffect, useState} from "react";
import styled from '@emotion/styled';
import {
  FormControl,
  InputLabel, LinearProgress,
  MenuItem, Radio,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import {SelectChangeEvent} from "@mui/material/Select";
import {CustomRadioButtons} from "../components/ButtonComponents";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker, LocalizationProvider} from "@mui/lab";
import {Body3, Body4} from "../components/TextComponents";
import {Color} from "../components/StyleUtils";
import {useGlobalConfigStore} from "../store/GlobalConfigStore";
import {red} from "@mui/material/colors";
import {isTspPortal} from "../utils/validUtil";

export interface CustomHeadCell<T> {
  id: keyof T;
  label: string;
  align?: 'left' | 'center' | 'right';
  width?: number | string
  rowSpan?: number
  colSpan?: number
  actionComponent?: any;
  children?: CustomHeadCell<any>[];
}

export type WithCustomRowData<T> = T & {
  key: string;
  edit?: boolean;
};

interface TableComponentsProps<T> {
  headCells: CustomHeadCell<any>[];
  bodyRows: WithCustomRowData<T>[];
  tableCell: (item: WithCustomRowData<T>, index: number) => JSX.Element;
  page: number;
  rowsPerPage: number;
  rowCount: number;
  showTotal?: boolean;
  rightContent?: React.ReactNode;
  titleContent?: string | React.ReactNode;
  hidePagination?: boolean;
  hideRowPerPage?: boolean;
  hideBoarderTopColor?: boolean;
  hideBoarder?: boolean;
  isCheckBox?: boolean;
  selectMode?: 'none' | 'single' | 'multiple';
  defaultSelect?: string[]
  selectRowSpan?: number;
  isLoading?: boolean;
  minWidth?: string;
  children?: React.ReactNode;
  renderRow?: React.ReactNode;
  handleClick?: (key: string) => void;
  onChangePagination?: (page: number, rowPerPage: number) => void;
  onSelectedKey?: (key: string[]) => void;
}

export const TableComponents = <T extends unknown>(props: TableComponentsProps<T>) => {
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(props.page);
  const [rowsPerPage, setRowsPerPage] = React.useState(props.rowsPerPage);

  useEffect(() => {
    if (props.onChangePagination) props.onChangePagination(page, rowsPerPage);
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (props.onSelectedKey) props.onSelectedKey(selected);
  }, [selected]);

  useEffect(() => {
    if (!!props.defaultSelect && props.defaultSelect.length > 0) {
      setSelected(props.defaultSelect)
    }
  }, [props.defaultSelect]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = props.bodyRows.map((n) => n.key);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: any, key: string) => {
    if (props.isCheckBox) {
      const selectedIndex = selected.indexOf(key);
      let newSelected: string[] = [];
      switch (props.selectMode) {
        case "single": {
          if (selectedIndex === -1) {
            newSelected = [key];
          } else if (selectedIndex === 0) {
            newSelected = [];
          }
          break;
        }
        case "none": {
          break;
        }
        case "multiple":
        default: {
          if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, key);
          } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
          } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
          } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
              selected.slice(0, selectedIndex),
              selected.slice(selectedIndex + 1)
            );
          }
          break;
        }
      }
      setSelected(newSelected);
    }

    if (props.handleClick) props.handleClick(key);
  };

  const totalPage = (props.rowCount % rowsPerPage) == 0 ?
    (props.rowCount / rowsPerPage) : Math.floor((props.rowCount / rowsPerPage) + 1);

  return (
    <Box sx={{width: '100%'}}>
      {props.showTotal && (
        <Stack
          flexDirection={'row'}
          padding={'12px 0'}
          justifyContent={'space-between'}
          alignItems={'center'}
          style={{
            borderRadius: props.hideBoarder ? '0' : '20px 20px 0 0',
            border: props.hideBoarder ? '0' : '1px solid #d7dae6',
            fontWeight: '500'
          }}
        >
          {props.titleContent ? (
            <Stack flexDirection={'row'}>
              <TitleContent>{props.titleContent}</TitleContent>
            </Stack>
          ) : (
            <Stack flexDirection={'row'}>
              <span>TOTAL</span>
              <HorizontalInterval size={'11px'}/>
              <span style={{color: Color.primary}}>{props.rowCount}</span>
            </Stack>
          )}

          {props.rightContent}
        </Stack>
      )}

      {props.isLoading && <LinearProgress/>}
      <TableContainer>
        <Table
          // stickyHeader
          sx={{
            ...(!!props.minWidth && {
              minWidth: props.minWidth,
            }),
            borderBottom: '1px solid #d7dae6',
            borderLeft: '1px solid #d7dae6',
            borderRight: '1px solid #d7dae6',
          }}
          // size={"small"}
        >
          <EnhancedTableHead
            headCells={props.headCells}
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={props.rowCount}
            isCheckBox={props.isCheckBox}
            hideBoarderTopColor={props.hideBoarderTopColor}
            selectMode={props.selectMode}
            selectRowSpan={props.selectRowSpan}
          />

          <EnhancedTableBody<T>
            rows={props.bodyRows}
            rowsPerPage={rowsPerPage}
            isSelected={(key: string) => selected.indexOf(key) !== -1}
            handleClick={handleClick}
            isCheckBox={props.isCheckBox}
            tableCell={props.tableCell}
            selectMode={props.selectMode}
            renderRow={props.renderRow}
            headCellCount={props.headCells.length}
          />
          {props.children}
        </Table>
      </TableContainer>

      {!props.hidePagination && (
        <Pagination
          curPage={page}
          totalPage={totalPage}
          hideRowPerPage={props.hideRowPerPage}
          rowsPerPage={rowsPerPage}
          handleChangePage={(event: unknown, newPage: number) => {
            setPage(newPage - 1);
          }}
          handleChangeRowsPerPage={(event: any) => {
            setRowsPerPage(event.target.value);
          }}
        />
      )}
    </Box>
  );
};

const EnhancedTableHead: React.FC<{
  headCells: CustomHeadCell<any>[];
  numSelected: number;
  rowCount: number;
  isCheckBox?: boolean;
  selectMode?: 'none' | 'single' | 'multiple';
  selectRowSpan?: number;
  hideBoarderTopColor?: boolean;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = (props) => {
  const {onSelectAllClick, numSelected, rowCount, isCheckBox, selectMode = 'multiple'} = props;

  return (
    <TableHead>
      <TableRow>
        {isCheckBox && (
          <TableCell
            padding="checkbox"
            rowSpan={props.selectRowSpan ?? 1}
            sx={{
              border: "1px solid #d7dae6",
              borderTop: `1px solid ${props.hideBoarderTopColor ? '#d7dae6' : '#4063ec'}`,
              backgroundColor: '#f5f5f5'
            }}>
            {selectMode === 'multiple' &&
                <CheckboxStyle
                    color="primary"
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected >= rowCount}
                    onChange={onSelectAllClick}
                    inputProps={{
                      'aria-label': 'select all desserts',
                    }}
                />
            }
          </TableCell>
        )}
        {props.headCells.map((headCell) => {
          const actionComponent = headCell.actionComponent ?? <></>;
          return (
            <TableCell
              key={headCell.id as string}
              align={headCell.align || 'center'}
              width={headCell.width}
              colSpan={headCell.colSpan ?? 1}
              rowSpan={headCell.rowSpan ?? 1}
              style={{
                border: "1px solid #d7dae6",
                borderTop: `1px solid ${props.hideBoarderTopColor ? '#d7dae6' : '#4063ec'}`,
                fontWeight: 600,
                backgroundColor: '#f5f5f5'
              }}>
              {headCell.label}
              {actionComponent}
            </TableCell>
          );
        })}
      </TableRow>
      {props.headCells.filter(i => Array.isArray(i.children)).map((i) => {
        return (
          <EnhancedTableHeadChild
            headCells={i.children}
            hideBoarderTopColor={props.hideBoarderTopColor}
          />
        )
      })}
    </TableHead>
  );
};

const EnhancedTableHeadChild: React.FC<{
  headCells: CustomHeadCell<any>[] | undefined;
  hideBoarderTopColor?: boolean;
}> = (props) => {
  if (!props.headCells || props.headCells.length === 0) return <></>;

  const renderChild = (cells: CustomHeadCell<any>[] | undefined) => {
    if (!cells || cells.length === 0) return <></>
    return (
      <EnhancedTableHeadChild
        headCells={cells}
        hideBoarderTopColor={props.hideBoarderTopColor}
      />
    )
  }

  return (
    <>
      <TableRow>
        {props.headCells.map((headCell) => {
          const actionComponent = headCell.actionComponent ?? <></>;
          return (
            <TableCell
              key={headCell.id as string}
              align={headCell.align || 'right'}
              colSpan={headCell.colSpan ?? 1}
              rowSpan={headCell.rowSpan ?? 1}
              style={{
                border: "1px solid #d7dae6",
                borderTop: `1px solid ${props.hideBoarderTopColor ? '#d7dae6' : '#4063ec'}`,
                fontWeight: 600,
              }}>
              {headCell.label}
              {actionComponent}
            </TableCell>
          );
        })}
      </TableRow>
      {props.headCells.filter(i => Array.isArray(i.children)).map((i) => {
        renderChild(i.children)
      })}
    </>
  );
};

const EnhancedTableBody = <T extends unknown>(props: {
  rows: WithCustomRowData<T>[],
  rowsPerPage: number,
  isSelected: (name: string) => boolean,
  selectMode?: 'none' | 'single' | 'multiple',
  tableCell: (data: WithCustomRowData<T>, index: number) => JSX.Element,
  handleClick: (event: any, name: string) => void,
  isCheckBox?: boolean,
  headCellCount?: number;
  renderRow?: React.ReactNode;
}) => {
  const {rowsPerPage, isSelected, handleClick, isCheckBox, selectMode = 'multiple'} = props;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = rowsPerPage - props.rows.length;

  return (
    <TableBody>
      {
        props.rows.length === 0 &&
          <TableCell colSpan={props.headCellCount} align={"center"}>조회 된 데이터가 없습니다.</TableCell>
      }
      {
        props.rows.length > 0 && props.rows.map((row, index) => {
            const isItemSelected = isSelected(row.key);
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <TableBodyRow
                key={row.key}
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                selected={isItemSelected}
                style={{border: "1px solid #d7dae6"}}
                onClick={(event: React.MouseEvent<HTMLTableRowElement>) => {
                  event.preventDefault();
                  if (typeof row.key === 'number') {
                    handleClick(event, row.key);
                  } else {
                    if (!row.key.includes('new')) {
                      handleClick(event, row.key);
                    }
                  }
                }}
              >
                {isCheckBox && (
                  <TableCell
                    key={'table-cell' + row.key}
                    padding="checkbox"
                    onClick={(evet) => {
                      if (typeof row.key === 'number') {
                        handleClick(evet, row.key);
                      } else {
                        if (!row.key.includes('new')) {
                          handleClick(evet, row.key);
                        }
                      }
                    }}
                  >
                    {selectMode === 'multiple' &&
                        <CheckboxStyle
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                        />
                    }
                    {selectMode === 'single' &&
                        <RadioBoxStyle
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                        />
                    }

                  </TableCell>
                )}
                {props.tableCell(row, index)}
              </TableBodyRow>
            );
          }
        )}
      {props.renderRow && props.renderRow}
      {emptyRows > 0 && (
        <Box sx={{}}>
          <VerticalInterval size={33 * emptyRows}/>
        </Box>
        // <TableRow
        //   style={{
        //     height: 33 * emptyRows,
        //   }}
        // >
        //   <TableCell colSpan={6}/>
        // </TableRow>
      )}
    </TableBody>
  );
};

export const TableSelectCellTsp: React.FC<{
  label: string
  selectLabel: string[]
  thSpan?: number
  thWidth?: string | number
  tdSpan?: number
  required?: boolean
  tdWidth?: string | number
  defaultLabel?: string
  division?: boolean
  onClick?: (selectValue: string) => void
}> = props => {
  const [select, SetSelect] = React.useState<string>(props.defaultLabel || "")
  useEffect(() => {
    if (!!props.defaultLabel) SetSelect(props.defaultLabel)
  }, [props.defaultLabel])
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 224,
        // width: 80
      },
    },
  };
  return <>
    {/*<TableHeaderCell width={props.thWidth} colSpan={props.thSpan}*/}
    {/*                 sx={{background: "#f5f5f5", fontWeight: "bold", paddingLeft: "20px"}}>*/}
    {/*  <Body3 weight={500}>{props.label}</Body3>*/}
    {/*  {props.required && <Body4 color={Color.topaz} style={{paddingLeft: '3px'}}>*</Body4>}*/}
    {/*</TableHeaderCell>*/}
    {/*<TableBodyCell*/}
    {/*  width={props.tdWidth}*/}
    {/*  colSpan={props.tdSpan}*/}
    {/*  sx={{borderRight: props.division ? "1px solid #d7dae6" : undefined}}*/}
    {/*>*/}
      <FormControl fullWidth style={{marginBottom: '15px'}}>
        {/*<InputLabel>{props.label}</InputLabel>*/}
        <Select
          size={'small'}
          required={props.required}
          name={props.label} //label={props.label}
          value={select}
          MenuProps={MenuProps}
          onChange={(event: SelectChangeEvent) => {
            if (props.onClick) props.onClick(event.target.value)
            SetSelect(event.target.value)
          }}>
          {
            props.selectLabel.map((m, i) => {
              return <MenuItem key={i} value={m}>
                <Body3>{m}</Body3>
              </MenuItem>
            })
          }
        </Select>
      </FormControl>
    {/*</TableBodyCell>*/}
  </>
}


export const TableSelectCell: React.FC<{
  label: string
  selectLabel: string[]
  thSpan?: number
  thWidth?: string | number
  tdSpan?: number
  required?: boolean
  tdWidth?: string | number
  defaultLabel?: string
  division?: boolean
  onClick?: (selectValue: string) => void
}> = props => {
  const [select, SetSelect] = React.useState<string>(props.defaultLabel || "")
  useEffect(() => {
    if (!!props.defaultLabel) SetSelect(props.defaultLabel)
  }, [props.defaultLabel])

  return <>
    <TableHeaderCell width={props.thWidth} colSpan={props.thSpan}
                     sx={{background: "#f5f5f5", fontWeight: "bold", paddingLeft: "20px"}}>
      <Body3 weight={500}>{props.label}</Body3>
      {props.required && <Body4 color={Color.topaz} style={{paddingLeft: '3px'}}>*</Body4>}
    </TableHeaderCell>
    <TableBodyCell
      width={props.tdWidth}
      colSpan={props.tdSpan}
      sx={{borderRight: props.division ? "1px solid #d7dae6" : undefined}}
    >
      <FormControl fullWidth>
        {/*<InputLabel>{props.label}</InputLabel>*/}
        <Select
          size={'small'}
          required={props.required}
          name={props.label} //label={props.label}
          value={select}
          onChange={(event: SelectChangeEvent) => {
            if (props.onClick) props.onClick(event.target.value)
            SetSelect(event.target.value)
          }}>
          {
            props.selectLabel.map((m, i) => {
              return <MenuItem key={i} value={m}>
                <Body3>{m}</Body3>
              </MenuItem>
            })
          }
        </Select>
      </FormControl>
    </TableBodyCell>
  </>
}

export const TableTextFieldCell: React.FC<{
  label: string
  inputType?: 'text' | 'number'
  defaultLabel?: string
  disabled?: boolean
  endText?: string
  thSpan?: number
  thWidth?: string | number
  tdSpan?: number
  tdWidth?: string | number
  required?: boolean
  division?: boolean
  multiline?: boolean
  phoneNumber?: boolean
  placeholder?: string
  wordCount?: number | any
  maxLength?: number
  whiteSpace?: 'pre-line' | 'pre-wrap' | 'nowrap' | 'normal'
  regexPattern?: RegExp
  onChange?: (text: string) => void
}> = props => {
  const [value, setValue] = useState<string>('')
  const {isDesktop} = useGlobalConfigStore()
  useLayoutEffect(() => {
    if (!!props.defaultLabel) {
      setValue(props.defaultLabel)
    }
  }, [props.defaultLabel])
  return <>
    <TableHeaderCell width={props.thWidth} colSpan={props.thSpan}
                     sx={{background: "#f5f5f5", fontWeight: "bold", paddingLeft: "20px"}}>
      <Body3 weight={500} style={{whiteSpace: props.whiteSpace}}>{props.label}</Body3>
      {props.required && <Body4 color={Color.topaz} style={{paddingLeft: '3px'}}>*</Body4>}
    </TableHeaderCell>
    <TableBodyCell
      width={props.tdWidth}
      colSpan={props.tdSpan}
      //sx={{borderRight: props.division ? "1px solid #d7dae6" : undefined}}
    >
      <Stack direction={props.endText ? 'row' : 'column'} alignItems={'center'}>
        <FormControl fullWidth>
          <TextField
            value={props.phoneNumber ? value.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3') : value}
            name={props.label}
            sx={{
              '*::-webkit-scrollbar': {
                width: '8px'
              },
              '*::-webkit-scrollbar-thumb': {
                backgroundColor: '#d7dae6',
                borderRadius: '10px'
              },
              '*::-webkit-scrollbar-track': {
                backgroundColor: '#fff',
                borderRadius: '10px'
              }
            }}
            //sx={{height: props.wordCount ? '120px' : 'unset'}}
            //label={props.label}
            inputProps={{maxLength:props.maxLength}}
            inputMode={'text'}
            type={props.inputType}
            disabled={props.disabled}
            placeholder={props.phoneNumber ? " '-' 없이 입력해주세요." : props.placeholder}
            //InputLabelProps={{style:{fontSize: isDesktop? '16px' : '14px'}}}
            //inputProps={{style:{fontSize: isDesktop? '16px' : '14px', height: '48px', padding:'0px', paddingLeft:'16px'}}}
            required={props.required}
            multiline={props.multiline}
            variant={"outlined"}
            size={"small"}
            rows={5}
            onChange={(e) => {
              let changeValue = e.target.value
              if (props.regexPattern) {
                changeValue = changeValue.replace(props.regexPattern, '')
              }
              if (props.onChange)
                props.onChange(changeValue)
              setValue(changeValue)
              if (props.wordCount <= value.length) {
                const idx = (props.wordCount - value.length) < 0 ? props.wordCount - value.length : -1;
                setValue(value.slice(0, idx));
                alert("제한글자를 지켜주세요")
              }
            }}/>
          {
            props.wordCount && <WordCount curWord={value.length} maxWord={props.wordCount}/>
          }
        </FormControl>
        {props.endText && <span style={{paddingLeft: "10px", whiteSpace: "nowrap"}}>{props.endText}</span>}
      </Stack>
    </TableBodyCell>
  </>
}

export const TableInputCell: React.FC<{
  label: string
  thSpan?: number
  thWidth?: string | number
  tdSpan?: number
  tdWidth?: string | number
  division?: boolean
  onChange?: (text: string) => void
}> = props => {
  return <>
    <TableHeaderCell width={props.thWidth} colSpan={props.thSpan}>
      <Body3>{props.label}</Body3>
    </TableHeaderCell>
    <TableBodyCell
      width={props.tdWidth}
      colSpan={props.tdSpan}
      sx={{borderRight: props.division ? "1px solid #d7dae6" : undefined}}
    >
      <FormControl fullWidth>
        <TextField
          label={<Body3>{props.label}</Body3>}
          variant={"outlined"}
          onChange={(e) => {
            if (props.onChange) props.onChange(e.target.value)
          }}/>
      </FormControl>
    </TableBodyCell>
  </>
}

export const TableDateTermCell: React.FC<{
  label: string
  thSpan?: number
  thWidth?: string | number
  tdSpan?: number
  tdWidth?: string | number
  division?: boolean
  onChange?: (text: string) => void
}> = props => {
  const [start, setStart] = React.useState<Date | null>(new Date());
  const [end, setEnd] = React.useState<Date | null>(new Date());

  return <>
    <TableHeaderCell width={props.thWidth} colSpan={props.thSpan}>
      {props.label}
    </TableHeaderCell>
    <TableBodyCell
      width={props.tdWidth}
      colSpan={props.tdSpan}
      sx={{borderRight: props.division ? "1px solid #d7dae6" : undefined}}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack direction="row" spacing={2}>
          <FormControl fullWidth>
            <DatePicker
              value={start}
              openTo={"day"}
              views={['year', 'month', 'day']}
              onChange={(newValue: any) => {
                setStart(newValue);
              }}
              renderInput={(params: any) => <TextField {...params} />}
            />
          </FormControl>
          <FormControl fullWidth>
            <DatePicker
              value={end}
              openTo={"day"}
              views={['year', 'month', 'day']}
              onChange={(newValue: any) => {
                setEnd(newValue);
              }}
              renderInput={(params: any) => <TextField {...params} />}
            />
          </FormControl>
        </Stack>
      </LocalizationProvider>
    </TableBodyCell>
  </>
}

export const TableRadioCell: React.FC<{
  label: string
  radioLabel: string[]
  defaultLabel?: string
  row?: boolean
  thSpan?: number
  thWidth?: string | number
  tdSpan?: number
  required?: boolean
  tdWidth?: string | number
  division?: boolean
  whiteSpace?: 'pre-line' | 'pre-wrap' | 'nowrap' | 'normal'
  onClick?: (selected: string) => void
}> = props => {
  return <>
    <TableHeaderCell
      width={props.thWidth} colSpan={props.thSpan}
      sx={{background: "#f5f5f5", fontWeight: "bold", paddingLeft: "20px"}}>
      <Body3 style={{whiteSpace: props.whiteSpace}} weight={500}>{props.label}</Body3>
      {props.required && <Body4 color={Color.topaz} style={{paddingLeft: '3px'}}>*</Body4>}
    </TableHeaderCell>
    <TableBodyCell
      width={props.tdWidth}
      colSpan={props.tdSpan}
      sx={{borderRight: props.division ? "1px solid #d7dae6" : undefined}}
    >
      <CustomRadioButtons
        row={props.row}
        defaultData={props.defaultLabel}
        data={props.radioLabel}
        onClick={(selected: string) => {
          if (props.onClick) props.onClick(selected)
        }}/>
    </TableBodyCell>
  </>
}

export const TableAttachCell: React.FC<{
  title: string
  row?: boolean
  thSpan?: number
  thWidth?: string | number
  tdSpan?: number
  tdWidth?: string | number
  required?: boolean
  division?: boolean
  onClick?: (selected: string) => void
  element: JSX.Element
  rightContent?: React.ReactNode;
}> = props => {
  return <>
    <TableHeaderCell
      width={props.thWidth} colSpan={props.thSpan}
      sx={{background: "#f5f5f5", fontWeight: "bold", minWidth: props.thWidth, paddingLeft: "20px"}}>
      <Body3 weight={500}>{props.title}</Body3>
      {props.required && <Body4 color={Color.topaz} style={{paddingLeft: '3px'}}>*</Body4>}
    </TableHeaderCell>
    <TableBodyCell
      width={props.tdWidth}
      colSpan={props.tdSpan}
      sx={{maxWidth: '420px', overflowX: 'auto'}}
    >
      <TableRow sx={{display: 'flex', justifyContent: props.rightContent ? 'space-between' : 'normal'}}>
        {props.element}{props.rightContent}
      </TableRow>
    </TableBodyCell>
  </>
}

export const CustomInfoTable = (props: {
  columnCount: number,
  elements: JSX.Element[]
  style?: React.CSSProperties
}) => {
  const spliceTableRow = (elements: JSX.Element[], size: number) => {
    const res = []
    let index = 0
    while (elements.length > 0) {
      const chunk = elements.splice(0, size)
      res.push(<TableRow key={`tableRow-${index}`}>{chunk}</TableRow>)
      index += 1
    }
    return res;
  }

  const row = spliceTableRow(props.elements, props.columnCount)
  return <TableContainer sx={{borderTop: "1px solid #1f2437", width: "100%", overflow: "hidden", ...props.style}}>
    <Table>
      <caption style={{padding:0}}></caption>
      <TableBody>
        {row}
      </TableBody>
    </Table>
  </TableContainer>
}

export const TableTextCell: React.FC<{
  title: string
  label: string | React.ReactNode
  thSpan?: number
  thWidth?: string | number
  tdSpan?: number
  tdWidth?: string | number
  division?: boolean
  height?: string | number
  rightContent?: React.ReactNode;
  onClick?: (selectValue: string) => void
  rowspan?: number
  padding?: string
  whiteSpace?: 'pre-line' | 'pre-wrap' | 'nowrap' | 'normal'
  label2?: string | React.ReactNode
}> = (props) => {
  return <>
    <TableHeaderCell
      width={props.thWidth} colSpan={props.thSpan} rowSpan={props.rowspan} style={{height:props.height}}
      sx={{background: "#f5f5f5", fontWeight: "bold", minWidth: props.thWidth, paddingLeft: '20px', padding:props.padding}}
    >
      <Body3 style={{whiteSpace:props.whiteSpace}} weight={500}>{props.title}</Body3>
    </TableHeaderCell>
    <TableBodyCell
      width={props.tdWidth}
      colSpan={props.tdSpan}
      height={props.height}
      sx={{minWidth: props.tdWidth}}
    >
      {props.label == "장비 반출 불가"
        ?
        <Body3 weight={500} style={{whiteSpace:'pre-wrap', overflow:'auto'}}>{props.label}</Body3>
        :
        <Body3 weight={300} style={{whiteSpace:'pre-wrap', maxHeight:'500px', overflow:'auto'}}>{props.label}</Body3>
      }
      {props.rightContent}
    </TableBodyCell>
  </>
}

export const TableTextCell2: React.FC<{
  // title: string
  label?: string | React.ReactNode
  thSpan?: number
  thWidth?: string | number
  tdSpan?: number
  tdWidth?: string | number
  division?: boolean
  height?: string | number
  rightContent?: React.ReactNode;
  onClick?: (selectValue: string) => void
  // rowspan?: number
  // label2?: string | React.ReactNode
}> = (props) => {
  return <>
    {/*<TableHeaderCell*/}
    {/*  width={props.thWidth} colSpan={props.thSpan} rowSpan={props.rowspan}*/}
    {/*  sx={{background: "#f5f5f5", fontWeight: "bold", minWidth: props.thWidth, paddingLeft: '20px'}}*/}
    {/*>*/}
    {/*  <Body3 weight={500}>{props.title}</Body3>*/}
    {/*</TableHeaderCell>*/}
    <TableBodyCell
      width={props.tdWidth}
      colSpan={props.tdSpan}
      height={props.height}
      sx={{minWidth: props.tdWidth}}
    >
      <Body3 weight={300}>{props.label}</Body3>
      {props.rightContent}
    </TableBodyCell>
    {/*<TableBodyCell*/}
    {/*  width={props.tdWidth}*/}
    {/*  colSpan={props.tdSpan}*/}
    {/*  height={props.height}*/}
    {/*  sx={{minWidth: props.tdWidth}}*/}
    {/*>*/}
    {/*  <Body3 weight={300}>{props.label2}</Body3>*/}
    {/*  {props.rightContent}*/}
    {/*</TableBodyCell>*/}
  </>
}

export const TableEmptyCell: React.FC<{
  thSpan?: number
  thWidth?: string | number
  tdSpan?: number
  tdWidth?: string | number
}> = (props) => {
  return <>
    <TableHeaderCell
      width={props.thWidth} colSpan={props.thSpan}
      sx={{minWidth: props.thWidth}}/>
    <TableBodyCell
      width={props.tdWidth}
      colSpan={props.tdSpan}
      sx={{minWidth: props.tdWidth}}/>
  </>
}


const TableBodyRow = styled(TableRow)`
  &.MuiTableRow-root {
    .MuiTableCell-root {
      border: 1px solid #d7dae6;
    }

    &.Mui-selected, &:hover {
      background-color: rgba(64, 99, 236, 0.1);

      //td {
      //  font-weight: 600;
      //}
    }
  }
`

const TableHeaderCell = styled(TableCell)`
  height: 56px;
  color: #222;
  font-weight: 600;
`
const TableBodyCell = styled(TableCell)`
  padding: ${isTspPortal ? '6px 20px' : '0 10px'};
  align-items: center;
  align-content: center;
  color: #666666;
`

export const CheckboxStyle = styled(Checkbox)`
  &.MuiCheckbox-root {
    padding: 0;
    margin-right: 10px;
    margin-left: 11px;
  }

  .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 4px;

    path {
      display: none;
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 20px;
    height: 20px;
    margin: -12px 0 0 -10px;
    border-radius: 3px;
    border: 1px solid #ccc;
  }

  &.Mui-checked {
    &:before {
      border: none;
      background-color: #4063ec;
      //background: url('/images/common/checkbox_active.png');
      background: url(${isTspPortal ? '/tsp/images/common/checkbox_active.png' : '/images/common/checkbox_active.png'});
      //background:  url('../images/common/checkbox_active.png');
    }
  }
`;

export const TitleCheckboxStyle = styled(Checkbox)`
  &.MuiCheckbox-root {
    padding: 0;
    margin-right: 10px;
    margin-left: 11px;
  }

  .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 4px;

    path {
      display: none;
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 20px;
    height: 20px;
    margin: -8px 0 0 -10px;
    border-radius: 3px;
    border: 1px solid #ccc;
  }

  &.Mui-checked {
    &:before {
      border: none;
      background-color: #4063ec;
      //background: url('/images/common/checkbox_active.png');
      background: url(${isTspPortal ? '/tsp/images/common/checkbox_active.png' : '../images/common/checkbox_active.png'});
      //background:  url('../images/common/checkbox_active.png');
    }
  }
`;

export const RadioBoxStyle = styled(Radio)`
  .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
  }
`;

const TitleContent = styled.span`
  font-size: 18px;
  font-weight: 500;
  height: 40px;
  display: flex;
  align-items: center;
`;