import React, {CSSProperties, Fragment, useEffect} from 'react';
import {Button, Modal, Stack} from '@mui/material';
import Box from '@mui/material/Box';
import {styled} from '@mui/styles';
import {VerticalInterval} from '../components/LayoutComponents';
import {Icons} from '../components/IconContainer';
import {CustomButton} from '../components/ButtonComponents';
import {Body2, H3} from "../components/TextComponents";

export type ModalType = 'normal' | 'confirm' | 'save' | 'transmit' | 'preview';

export type ModalParam = {
  open: boolean;
  content?: string;
  type?: string;
  hideBackdrop?: boolean;
  title?: string;
  isDist?: boolean;
  noButton?: boolean;
  confirmLabel?: string
  cancelLabel?: string
  onConfirm?: () => void;
  onClose?: () => void;
  outlinedblack?: boolean;
  outlined?: boolean;
  borderRadius?: string;
  style?: CSSProperties;
}

export const ModalComponents: React.FC<ModalParam> = (props) => {
    const {type, isDist, noButton} = props;
    let confirmLabel = ""
    if (type == "normal") {
      confirmLabel = "확인";
    } else if (type == "save") {
      confirmLabel = "저장";
    } else if (type == "transmit") {
      confirmLabel = "전송";
    } else if (type == "complete") {
      confirmLabel = "완료";
    } else if (type == 'preview') {
      confirmLabel = '닫기'
    } else {
      confirmLabel = "예";
    }
    if (props.confirmLabel) confirmLabel = props.confirmLabel

    const handlerConfirm = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      if (props.onConfirm) props.onConfirm();
      document.body.style.overflow = 'unset';
    };

    const handlerExit = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      if (props.onClose) props.onClose();
      document.body.style.overflow = 'unset';
    };

    const handlerModalClose = (
      event: {},
      reason: 'backdropClick' | 'escapeKeyDown'
    ) => {
      if (props.onClose) props.onClose();
      document.body.style.overflow = 'unset';
    };

    useEffect(() => {
      if (props.open) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    }, [props.open])

    return (
      <>
        <Modal
          disableScrollLock
          open={props.open}
          onBackdropClick={(e) => {
            e.stopPropagation()
          }}
          onClose={handlerModalClose}
          hideBackdrop={props.hideBackdrop}
          sx={{margin: '0 15px'}}
        >
          <ModalBoxContainer style={props.style}>
            <Stack justifyContent={'space-between'} flexDirection={'column'}>
              <Box style={{position:'absolute', right: 0, top: 20}}>
                <Button
                  style={{width: '24px', height: '24px'}}
                  onClick={handlerExit}
                >
                  {<Icons.Exit/>}
                </Button>
              </Box>

              <Box sx={{pb: '32px'}}>
                {props.children ? <Fragment>
                    {props.title ? (
                      <h2>{props.title}</h2>
                    ) : (
                      <VerticalInterval size={'40px'}/>
                    )}
                    {props.children}
                  </Fragment> : <Fragment>
                  {
                    props.title ?
                      <div style={{display:'flex', justifyContent:'center', marginBottom:'20px'}}><H3 bold>{props.title}</H3></div> :
                      <VerticalInterval size={'20px'}/>
                  }
                  <Box display={'flex'} justifyContent={'center'}>
                    <Body2 center style={{whiteSpace:'pre-wrap'}}>{props.content}</Body2>
                  </Box>
                </Fragment>
                }
              </Box>

              <Stack justifyContent={type === 'preview' ? 'space-between' : 'center'} direction={'row'} spacing={'10px'}>
                {isDist || (
                  <CustomButton
                    type={'large'}
                    label={props.cancelLabel || '취소'}
                    color={props.outlined ? 'outlined' : 'primary'}
                    onClick={handlerExit}
                  />
                )}
                {
                  type === 'preview' && (
                    <CustomButton
                      type={'large'} color={'outlined'}
                      label={'오늘은 그만보기'}
                      onClick={handlerExit}
                    />
                  )}
                <CustomButton label={confirmLabel} type={'large'} color={'primary'} onClick={handlerConfirm}/>
              </Stack>
            </Stack>
          </ModalBoxContainer>
        </Modal>
      </>
    );
  }
;

const ModalBoxContainer = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth:'480px',
  width: '100%',
  backgroundColor: 'white',
  borderRadius: '20px',
  margin: '0',
  padding: "24px",
  '@media (min-width: 320px) and (max-width: 768px)' : {
    maxWidth: '320px',
  }
})
