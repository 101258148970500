export const checkValidity = (formId: string) => {
  const form = document.getElementById(formId)
  let valid = true
  let first = false

  if (form) {
    const elementList = form.getElementsByTagName('div')
    if (elementList) {
      for (let i = 0; i < elementList.length; i++) {
        let div = elementList.item(i);

        if (div?.classList.contains('MuiInputBase-root')) {
          let kind = 'input'
          div.classList.forEach(v => {
            if (v.includes('MuiInputBase-multiline')) {
              kind = 'multiline'
              return false
            } else if (v.includes('MuiSelect-root')) {
              kind = 'select'
              return false
            }
          })

          const selector = kind == 'multiline' ? 'textarea' : 'input'
          const input = div.querySelector(selector)
          if (input?.required) {
            if (input.validity.valueMissing) {
              // 처음 값없는 필드로 이동.
              if (!first) {
                input.focus()
                input.scrollIntoView({behavior: 'smooth', block: "end"})
                first = true
              }

              div.classList.add('Mui-error')
              if (!div.parentElement?.querySelector('p')) {
                let p = document.createElement('p');
                p.innerText = `${input.name ? input.name : '내용'}를 입력해주세요.`
                p.style['fontSize'] = '0.75rem'
                p.style['color'] = 'red'
                p.style['margin'] = '10px 0 0 0'
                if (selector == 'textarea') {
                  p.style['position'] = 'absolute'
                  p.style['bottom'] = '-22px'
                }
                div.parentElement?.append(p)
              }

              // onChange이벤트시 valid처리 했던 event 제거
              if (kind == 'select') {
                div.addEventListener('DOMNodeInserted', (event) => {
                  const p = div?.parentElement?.querySelector('p')
                  if (p) div?.parentElement?.removeChild(p)
                }, false)
              } else {
                input.addEventListener('change', (event) => {
                  const p = div?.parentElement?.querySelector('p')
                  if (p) div?.parentElement?.removeChild(p)
                })
              }
              valid = false
            }
          }
        }
      }
    }
  }

  return valid
}

export const isTspPortal = process.env.REACT_APP_PACKAGE ? process.env.REACT_APP_PACKAGE == 'tsp' : false;

/** 8자리 생년월일 YYYY-mm-DD로 변경 */
export const validBirthday = (value: string) => {
  let date = value.replace(/[^0-9]/g, '').slice(0, 8)
  if (date && date.length < 5) {
    return date
  }

  let DataFormat = "$1-$2";
  let RegDateFmt: RegExp = /([0-9]{4})([0-9]+)/;

  if (date.length > 6) {
    DataFormat = "$1-$2-$3"; // 포맷(-)을 바꾸려면 이곳을 변경
    RegDateFmt = /([0-9]{4})([0-9]{2})([0-9]+)/;
  }

  return date.replace(RegDateFmt, DataFormat);
}

/** 전화번호 $1-$2-$3로 변경 (최대 11자리) */
export const validPhoneNumber = (value: string) => {
  return value? value
    .replace(/[^0-9]/g, '')
    .replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, '$1-$2-$3')
    .replace('--', '-') : ''
}

export const toCurrency = (value: number, suffix = "") => {
  if (typeof value !== "number") return 0 + suffix;
  return value.toLocaleString('ko-KR') + suffix;
}