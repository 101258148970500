import React, {Fragment, lazy, useCallback, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import authentication, { AuthenticationType } from 'shared/authentication';
import ExtendValidTime from 'shared/components/ExtendValidTime';
import dayjs from 'shared/libs/dayjs';
import Studio from "./Studio";
import Space from "./Space";
import Empty from "./Empty";
import Basic from "./Basic";
import { fetchRefreshToken } from '../fetches';
import {useRouteStore} from "../store/RouteConfigStore";
import {RefreshToken} from "../api/RefeshToken";
import {isTspPortal} from "../utils/validUtil";
import api from "../api";

export type LayoutType = 'studio' | 'promotion' | 'space' | 'paper' | 'empty' | 'basic';
export const ThemeContext = React.createContext({label:""});

const Layout: React.FC = props => {
  const navigate = useNavigate()
  const location = useLocation()
  // const {data} = useSWR<AuthenticationType>('authentication');
  const config = useRouteStore()
  //* 토근 갱신
  const handleRefresh = async () => {
    const res = await RefreshToken()
    // fetchRefreshToken().then((res) => authentication.set(res.data)).catch((e) => {
    //   console.log('handleRefresh catch')
    //   debugger
    //   const {status, data} = e.response;
    //   if (data && data?.status == 401 && data?.error == 'Unauthorized'){
    //     const domain = process.env.REACT_APP_SSO_CHECK_PATH
    //     window.location.href = `${domain}?nextUrl=${window.btoa(window.location.href)}`
    //   }
    //   return false;
    // })
  };

  //* 토큰 만료
  const handleExpired = () => {
    navigate(`${isTspPortal? '/tsp' : '/signin'}/signout`);
  };

  const syncNavigate = () => {
    //* 마지막으로 업데이트 한 시간으로 부터 2초 이상 이면 갱신
    const data = authentication.get()
    if (!!data && dayjs().diff(data!.updateAt, 's') > 1) {
      handleRefresh();
    }
  };

  const visitCheck = useCallback((path: string) => {
    // 요청사항으로 메인페이지에서만 체크되도록 구현.
    /*
    if (!authentication.getUser()) {
      if (path === '/' || path === '/tsp/') {
        api({
          url: `${process.env.REACT_APP_DOMAIN_COMMON_BNET}/common/api/log/vstrconect-log`,
          method: 'post',
          data: {
            apiSysId: path === '/'? 'API_PMS' : 'API_TSP',
            linkUrl: window.location.href,
          }
        })
      }
    }
    */
    if (path === '/' || path === '/tsp/') {      
      api({
        url: `${process.env.REACT_APP_DOMAIN_COMMON_BNET}/common/api/log/vstrconect-log`,        
        method: 'post',
        data: {
          apiSysId: path === '/'? 'API_PMS' : 'API_TSP',
          login: authentication.getUser()? true : false,
          loginId: authentication.getUser()? authentication.getMemberData().loginId : "",            
          linkUrl: window.location.href,
        }
      })
    }
  }, [authentication.getUser()])

  useEffect(() => visitCheck(location.pathname),[location])
  useEffect(syncNavigate, [navigate]);
  useEffect(() => {
    if (config.middleware.includes("auth") && !authentication.getToken()) {
      const domain = process.env.REACT_APP_SSO_CHECK_PATH
      window.location.href = `${domain}?nextUrl=${window.btoa(window.location.href)}`
    }
  },[config.middleware])

  if (config.middleware.includes('factor')) {
    const key = sessionStorage.getItem('__FACTOR_KEY__');
    if (!key) {
      navigate(`signin/factor?nextUrl=${window.btoa( window.location.href)}`)
    }
  }

  const type = config.layout.replace(/^\w/, function (a) {
    return a.toUpperCase();
  });
  const Component: any = {Studio, Space, Empty, Basic}[type];

  return <Fragment>
    {/*<ThemeContext.Provider value={{label:config.themeContextLabel}}>*/}
    <Component>{props.children}</Component>
    {/*</ThemeContext.Provider>*/}
    <ExtendValidTime onRefresh={handleRefresh} onExpired={handleExpired}/>
  </Fragment>
}

export default Layout;
