import useSWR from 'swr';
import { NavLink, useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Hidden,
  IconButton,
  InputLabel,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import { RouteType } from '../../utils/RouteUtiles';
import {
  HorizontalInterval,
  VerticalInterval,
} from '../../components/LayoutComponents';
import { CustomIconButton } from '../../components/ButtonComponents';
import { Icons } from '../../components/IconContainer';
import { useGlobalConfigStore } from '../../store/GlobalConfigStore';
import { Body3, Body4 } from '../../components/TextComponents';
import { Breakpoint, Color } from '../../components/StyleUtils';
import styled from '@emotion/styled';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useRouteStore } from '../../store/RouteConfigStore';
import { useResize } from '../../components/useResize';
import { isTspPortal } from '../../utils/validUtil';
import { useQuery } from 'react-query';
import api from '../../api';
import MenuItem from '@mui/material/MenuItem';

const agencies = [
  { page: 'http://aica-gj.kr', nm: '사업단대표홈페이지' },
  { page: 'http://ai365.or.kr', nm: 'AI기업협력센터' },
  { page: 'http://www.gwangju.go.kr', nm: '광주광역시' },
  { page: 'http://nipa.kr', nm: '정보통신산업진흥원' },
  { page: 'http://msit.go.kr', nm: '과학기술정보통신부' },
];

function Footer() {
  // const location = useLocation();
  // const {data: routes = []} = useSWR('route://service');
  const { routes } = useRouteStore();
  const { isDesktop } = useGlobalConfigStore();
  // const size = useResize()
  const rootPath = isTspPortal ? '/tsp' : '';
  const isMobile = !isDesktop || window.innerWidth < 1280;
  const inputComponent = useRef<HTMLInputElement>(null);
  const [relatedAgencies, setRelatedAgencies] = useState<string>('');

  return (
    <footer
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        background: '#fff',
        position: 'relative',
        zIndex: '1000',
      }}
    >
      <Stack
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <RelationPageLink isDeskTop={!isMobile} />
        <Stack
          flexDirection={'row'}
          justifyContent={'flex-start'}
          sx={{ maxWidth: '1260px', width: '100%' }}
        >
          {!isMobile && (
            <Stack
              padding={'60px 0 80px'}
              justifyContent={'space-between'}
              flex={!isMobile ? '0 0 70%' : ''}
            >
              <Stack flexDirection={'row'}>
                {!isMobile &&
                  routes
                    .filter((f) => f.readYn)
                    .map((row: RouteType, i: number) => {
                      return (
                        <Box
                          key={i}
                          sx={{
                            width: '160px',
                            marginRight: '20px',
                            alignItems: 'left',
                          }}
                        >
                          {/*<Button sx={{fontWeight: 'bold', color: '#222222', fontSize: '15px', paddingLeft: 0, marginBottom:"20px"}}>*/}
                          {/*  {row.label}*/}
                          {/*</Button>*/}
                          <Body3 bold>{row.label}</Body3>
                          <VerticalInterval size={'20px'} />
                          <Stack sx={{ display: 'flex', gap: '10px' }}>
                            {(row.children || [])
                              .filter((f) => f.readYn)
                              .map((child: RouteType, j: number) => {
                                return (
                                  <Box key={'children-' + j}>
                                    <LinkBox
                                      to={`${rootPath}${child.path}`}
                                      replace
                                    >
                                      <Body4 color={Color.warm_gray}>
                                        {child.label}
                                      </Body4>
                                    </LinkBox>
                                  </Box>
                                );
                              })}
                          </Stack>
                        </Box>
                      );
                    })}
              </Stack>
              <SimpleMenu isDesktop={!isMobile} />
            </Stack>
          )}
          {!isMobile && (
            <Box
              style={{
                borderRight: '1px solid rgb(204,204,204,0.5)',
                height: '100%',
              }}
            />
          )}
          <Box
            padding={!isMobile ? '60px 0 80px 80px' : '40px 0 50px 15px'}
            flex={!isMobile ? '0 0 30%' : ''}
          >
            <img
              alt={'footerLogo'}
              src={`${rootPath}/images/img/logo_footer.svg`}
              style={{ width: '105px' }}
            />
            {!isMobile || <SimpleMenu isDesktop={!isMobile} />}
            <VerticalInterval size={'21px'} />
            <Body4 color={Color.warm_gray} style={{ letterSpacing: -0.56 }}>
              광주광역시 북구 첨단과기로 176번길 11 3층
            </Body4>
            {!isMobile ? <VerticalInterval size={'10px'} /> : ''}
            <Stack direction={'row'} gap={'12px'}>
              <Body4 color={Color.warm_gray} style={{ letterSpacing: -0.56 }}>
                대표번호 TEL. 062-610-3900
              </Body4>
            </Stack>
            <Stack direction={'row'} gap={'12px'}>
              <Body4 color={Color.warm_gray} style={{ letterSpacing: -0.56 }}>
                장애문의 TEL. 062-610-3919
              </Body4>
            </Stack>
            <VerticalInterval size={'20px'} />
            <Body4 color={Color.warm_gray} style={{ fontSize: '13px' }}>
              ©2021 인공지능산업융합사업단.ALL RIGHTS RESERVED
            </Body4>
            {/*<VerticalInterval size={isMobile ? '20px' : '55px'}/>*/}

            <FormControl
              fullWidth
              sx={{ margin: isMobile ? '16px 0 20px' : '20px 0 16px' }}
              className={'swiper-no-swiping'}
            >
              <Select
                displayEmpty
                value={relatedAgencies}
                MenuProps={{
                  anchorOrigin: { horizontal: 'left', vertical: 'top' },
                  transformOrigin: { horizontal: 'left', vertical: 'bottom' },
                }}
                sx={{
                  width: '240px',
                  height: '40px',
                  color: Color.warm_gray,
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: Color.line,
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: Color.line,
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: Color.line,
                  },
                }}
                renderValue={(value) => {
                  const nm = agencies.find((f) => f.page === value)?.nm;
                  return (
                    <em style={{ fontSize: '13px', color: '#707070' }}>
                      {nm ? nm : '유관기관 사이트'}
                    </em>
                  );
                }}
                onChange={(e) => {
                  setRelatedAgencies(e.target.value);
                  window.open(e.target.value);
                }}
              >
                {agencies.map((m) => {
                  return (
                    <MenuItem
                      sx={{
                        fontSize: '13px',
                        color: '#707070',
                        lineHeight: '19px',
                      }}
                      value={m.page}
                    >
                      {m.nm}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Stack flexDirection={'row'} gap={'8px'}>
              <CustomIconButton
                icon={Icons.Facebook}
                style={{ padding: 0 }}
                onClick={() => {
                  window.open(
                    `https://www.facebook.com/aicongwangju/`,
                    '_blank'
                  );
                }}
              />
              <CustomIconButton
                icon={Icons.Instagram}
                style={{ padding: 0 }}
                onClick={() => {
                  window.open(
                    `https://www.instagram.com/aica_gwangju/`,
                    '_blank'
                  );
                }}
              />
              <CustomIconButton
                icon={Icons.Youtube}
                style={{ padding: 0 }}
                onClick={() => {
                  window.open(
                    `https://www.youtube.com/channel/UCC5t5AArvuZ7weH_a8VuFNg`,
                    '_blank'
                  );
                }}
              />
            </Stack>
          </Box>
        </Stack>
      </Stack>

      {/*<img src={'/images/common/othergroup_01.png'}/>*/}
    </footer>
  );
}

const LinkBox = styled(NavLink)`
  :focus-visible {
    outline: 3px solid #aaa;
  }
`;

const SimpleMenu = (props: { isDesktop: boolean }) => {
  return (
    <Stack
      direction={props.isDesktop ? 'row' : 'column'}
      spacing={props.isDesktop ? '30px' : ''}
      mt={props.isDesktop ? '60px' : '30px'}
      style={{ flexWrap: 'wrap' }}
    >
      <Stack direction={'row'} alignItems={'center'}>
        <NavLink
          to={''}
          onClick={() => {
            const domain = process.env.REACT_APP_DOMAIN;
            window.location.href = `${domain}/information?preUrl=${window.btoa(
              window.location.href
            )}`;
          }}
        >
          <Body4 nowrap bold>
            {'개인정보처리방침'}
          </Body4>
        </NavLink>
        <Box
          style={{
            borderRight: props.isDesktop ? '0px' : `1px solid #cccccc`,
            margin: props.isDesktop ? '0px' : '1px 16px 1px 16px',
            marginRight: props.isDesktop ? '30px' : '16px',
            height: props.isDesktop ? '0px' : '12px',
          }}
        ></Box>
        <NavLink
          to={''}
          onClick={() => {
            const domain = process.env.REACT_APP_DOMAIN;
            window.location.href = `${domain}/TosContent?preUrl=${window.btoa(
              window.location.href
            )}`;
          }}
        >
          <Body4 nowrap color={Color.warm_gray}>
            {'이용약관'}
          </Body4>
        </NavLink>
        <Box
          style={{
            borderRight: props.isDesktop ? '0px' : `1px solid #cccccc`,
            margin: props.isDesktop ? '0px' : '1px 16px 1px 16px',
            marginRight: props.isDesktop ? '30px' : '16px',
            height: props.isDesktop ? '0px' : '12px',
          }}
        ></Box>
        <NavLink
          to={
            isTspPortal
              ? `/tsp/About/FAQ`
              : `/SupportForUse/FrequentlyAskedQuestions`
          }
          replace
        >
          <Body4 nowrap color={Color.warm_gray}>
            {'FAQ'}
          </Body4>
        </NavLink>
        <Box
          style={{
            borderRight: props.isDesktop ? '0px' : `1px solid #cccccc`,
            margin: props.isDesktop ? '0px' : '1px 16px 1px 16px',
            marginRight: props.isDesktop ? '30px' : '16px',
            height: props.isDesktop ? '0px' : '12px',
          }}
        ></Box>

        {isTspPortal ? (
          <a href={`${process.env.REACT_APP_DOMAIN}/SupportForUse/UserManual`}>
            <Body4 nowrap color={Color.warm_gray}>
              {'사용자매뉴얼'}
            </Body4>
          </a>
        ) : (
          <NavLink to={`/SupportForUse/UserManual`} replace>
            <Body4 nowrap color={Color.warm_gray}>
              {'사용자매뉴얼'}
            </Body4>
          </NavLink>
        )}
        <Box
          style={{
            borderRight: props.isDesktop ? '0px' : `1px solid #cccccc`,
            margin: props.isDesktop ? '0px' : '1px 16px 1px 16px',
            marginRight: props.isDesktop ? '30px' : '16px',
            height: props.isDesktop ? '0px' : '12px',
          }}
        ></Box>

        {isTspPortal ? (
          <a
            href={`${process.env.REACT_APP_DOMAIN}/SupportForUse/ReferenceRoom`}
          >
            <Body4 nowrap color={Color.warm_gray}>
              {'자료실'}
            </Body4>
          </a>
        ) : (
          <NavLink to={`/SupportForUse/ReferenceRoom`} replace>
            <Body4 nowrap color={Color.warm_gray}>
              {'자료실'}
            </Body4>
          </NavLink>
        )}
      </Stack>
    </Stack>
  );
};

const RelationPageLink = (props: { isDeskTop: boolean }) => {
  const [swiper, setSwiper] = useState<any | null>(null);
  const [stopBoolean, setStopBoolean] = useState<boolean>(true);
  const [stopClassName, setPalyClassName] = useState('');
  const [controllerName, setControllerName] = useState<string>('play');
  const [swiperData, setSwiperData] = useState<Partial<BannerInfo>[]>([]);

  // USP Admin에서 등록된 배너값 불러와서 공통적으로 사용.
  const banner = useQuery('banner', async () => await fetchBannerList());

  useEffect(() => {
    if (!banner.isLoading && !banner.isFetching) {
      if (!!banner.data) {
        setSwiperData(banner.data.list);
      }
    }
  }, [banner.data, banner.isLoading, banner.isFetching]);

  const handlePlay = (b: boolean) => {
    if (b) {
      swiper.autoplay.stop();
      setPalyClassName('is-stop');
      setControllerName('stop');
    } else {
      swiper.autoplay.start();
      setPalyClassName('');
      setControllerName('play');
    }
    console.log(stopBoolean);
  };

  const rootPath = isTspPortal ? '/tsp' : '';
  return (
    <Box
      sx={{
        borderBottom: '1px solid rgb(204,204,204,0.5)',
        borderTop: '1px solid rgb(204,204,204,0.5)',
        height: '80px',
        width: '100%',
      }}
    >
      {swiperData && swiperData.length > 0 && (
        <SwiperContent
          loopFillGroupWithBlank={true}
          loop
          navigation
          spaceBetween={props.isDeskTop ? 60 : 24}
          slidesPerView={'auto'}
          autoplay={{
            delay: 1000,
            disableOnInteraction: false,
          }}
          pagination={{
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
          }}
          modules={[Navigation, Autoplay]}
          onSwiper={(swiper) => setSwiper(swiper)}
          className={`${stopClassName}`}
          style={{ height: '100%' }}
        >
          {swiperData.map((m, i) => {
            const type = props.isDeskTop ? 'PC' : 'MOBILE';
            const url = `${process.env.REACT_APP_DOMAIN_COMMON_BNET}/common/api/banners/${m.bannerId}/images/${type}`;
            return (
              <SwiperSlide>
                <SwiperSlideItem
                  href={m.targetUrl ? m.targetUrl : undefined}
                  target="_blank"
                  src={url}
                >
                  {m.bannerNm}
                </SwiperSlideItem>
              </SwiperSlide>
            );
          })}
          {/*<SwiperSlide>*/}
          {/*  <SwiperSlideItem href="https://www.nipa.kr" target="_blank" src={`${rootPath}/images/common/othergroup_01.png`}/>*/}
          {/*</SwiperSlide>*/}
          {/*<SwiperSlide>*/}
          {/*  <SwiperSlideItem href="http://www.kopti.re.kr" target="_blank" src={`${rootPath}/images/common/othergroup_02.png`}/>*/}
          {/*</SwiperSlide>*/}
          {/*<SwiperSlide>*/}
          {/*  <SwiperSlideItem href="http://www.gjtp.or.kr" target="_blank" src={`${rootPath}/images/common/othergroup_03.png`}/>*/}
          {/*</SwiperSlide>*/}
          {/*<SwiperSlide>*/}
          {/*  <SwiperSlideItem href="https://www.gigca.or.kr" target="_blank" src={`${rootPath}/images/common/othergroup_04.png`}/>*/}
          {/*</SwiperSlide>*/}
          {/*<SwiperSlide>*/}
          {/*  <SwiperSlideItem href="https://www.gmcc.or.kr" target="_blank" src={`${rootPath}images/common/othergroup_05.png`}/>*/}
          {/*</SwiperSlide>*/}
          <SwiperControllerBtns rootPath={rootPath}>
            <Box
              component={'button'}
              type="button"
              className="ico"
              onClick={() => {
                setStopBoolean(!stopBoolean);
                handlePlay(stopBoolean);
              }}
            >
              <Typography component={'span'}>{controllerName}</Typography>
            </Box>
          </SwiperControllerBtns>
        </SwiperContent>
      )}
    </Box>
  );
};

const SwiperControllerBtns = styled('div')<{ rootPath: string }>`
  display: flex;
  position: absolute;
  right: 29px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
  @media (max-width: ${Breakpoint.mobile}) {
    right: 80px;
    background-color: transparent;
  }

  .ico {
    width: 24px;
    height: 24px;
    background: url('${(props) =>
        props.rootPath}/images/common/footer_stop.png')
      no-repeat center / contain;

    .swiper.is-stop & {
      background-image: url('${(props) =>
        props.rootPath}/images/common/footer_player.png');
    }

    > span {
      position: absolute;
      width: 0;
      height: 0;
      line-height: 0;
      overflow: hidden;
      text-indent: -9999px;
    }
  }
`;

const SwiperSlideItem = styled('a')<{ src: string }>`
  display: block;
  background-image: url(${(props) => props.src});
  width: 180px;
  height: 32px;
  text-indent: -9999px;
`;

const SwiperContent = styled(Swiper)`
  max-width: 1260px;
  margin: 0 auto;
  padding: 12px 50px;

  .swiper-slide {
    display: flex;
    width: 180px;
    align-items: center;
  }

  .swiper-button-prev {
    left: 0;
    background-color: #fff;
  }

  .swiper-button-prev:after {
    color: #999;
    font-size: 15px;
    padding-top: 0;
    font-weight: bold;
  }

  .swiper-button-next {
    right: 0;
    background-color: #fff;
  }

  .swiper-button-next:after {
    color: #999;
    font-size: 15px;
    padding-top: 0;
    font-weight: bold;
  }

  @media (min-width: 320px) and (max-width: 1280px) {
    .swiper-button-prev {
      left: auto;
      right: 50px;
      background-color: transparent;
    }

    .swiper-button-next {
      right: 10px;
      background-color: transparent;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 160px;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 55%
      );
      z-index: 1;
    }
  }
`;
const fetchBannerList = () =>
  api({
    url: `${process.env.REACT_APP_DOMAIN_COMMON_BNET}/common/api/banners/now/PORTAL_USP`,
    method: 'get',
  });

interface BannerInfo {
  bannerId: string;
  bannerNm: string;
  systemId: string;
  beginDt: number;
  endDt: number;
  targetUrl: string;
  newWindow: boolean;
  pcImageFileId: string;
  mobileImageFileId: string;
  enabled: boolean;
  fmtEndDay: string;
  fmtBeginTm: string;
  fmtBeginDay: string;
  fmtEndTm: string;
  fmtBeginDt: string;
  fmtCreatedDt: string;
  fmtCreatedDay: string;
  fmtEndDt: string;
}

function setStopBoolean(arg0: boolean) {
  throw new Error('Function not implemented.');
}

export default Footer;
