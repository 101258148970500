import authentication from "../authentication";
import axios from "../libs/axios";

export const RefreshToken = async () => {
  try {
    const res = await axios({
      url: `/member/api/login/refresh-token/member`,
      method: 'post',
      baseURL: process.env.REACT_APP_DOMAIN_MEMBER_BNET,
    })

    if (res && res.data) {
      authentication.set(res.data)
      return true
    }
  }catch (e:any) {
    const {data} = e.response;
    if (data?.status == 401 && data?.error == "Unauthorized") {
      // const domain = process.env.REACT_APP_SSO_CHECK_PATH
      window.location.href = `${process.env.REACT_APP_SSO_CHECK_PATH}?nextUrl=${window.btoa(window.location.href)}`
    }
    return false
  }
  
  return false
}