import create from "zustand";
import {LayoutType} from "../layout";
import {RouteData} from "../utils/RouteUtiles";
import {PortalType} from "../DynamicRouter";

interface State{
  routes: RouteData[]
  memberServerUrl: string
  defaultLayout: LayoutType
  layout: LayoutType
  themeContextLabel: string
  middleware: string[]
  portal: PortalType
  routeLoading: boolean

  setRoutes: (routes: RouteData[]) => void
  setMemberServer: (memberServer: string) => void
  setLayout: (layout: LayoutType) => void
  setDefaultLayout: (layout: LayoutType) => void
  setMiddleware: (middleware: string[]) => void
  setThemeLabel: (label: string) => void
  setPortal: (portal: PortalType) => void
  setRouteLoading: (loading: boolean) => void
}

export const useRouteStore = create<State>( set => ({
  routes: [],
  memberServerUrl: `${process.env.REACT_APP_DOMAIN_MEMBER_DEV}`,
  themeContextLabel: '',
  layout: "studio",
  defaultLayout: "studio",
  middleware: [],
  portal: 'PORTAL_USP',
  routeLoading: false,

  setRoutes: (routes: RouteData[]) => {
    set(state => ({
      routes: routes
    }))
  },
  setMemberServer: (memberServer: string) => {
    set(state => ({
      memberServerUrl: memberServer
    }))
  },
  setLayout: (layout: LayoutType) => {
    set(state => ({
      layout: layout
    }))
  },
  setDefaultLayout: (layout: LayoutType) => {
    set(state => ({
      defaultLayout: layout
    }))
  },
  setMiddleware: (middleware: string[]) => {
    set(state => ({
      middleware: middleware
    }))
  },
  setThemeLabel: (label: string) => {
    set( state => ({
      themeContextLabel: label
    }))
  },
  setPortal: (portal: PortalType) => {
    set( state => ({
      portal: portal
    }))},
  setRouteLoading: (loading: boolean) => {
    set( state => ({
      routeLoading: loading
    }))
  }
}))