// 사업관리 메인 라우트
import React from "react";
import {Loader, middleware, RouteType} from "shared/utils/RouteUtiles";

export const MainRoute: RouteType[] = [
  {
    index: true,
    element: (
      <Loader
        route={{
          label: 'home',
          layout: 'basic',
          element: React.lazy(() => import('~/pages/Home')),
        }}
      />
    ),
  },
  {
    path: '/biz',
    label: 'biz Home',
    element: (
      <Loader
        route={middleware({
          label: 'biz',
          layout: 'basic',
          element: React.lazy(() => import('~/pages/biz/Home')),
        },['auth'])}
      />
    ),
  },
].map((route: Partial<RouteType>) => ({
  ...route,
  layout: 'space',
})) as RouteType[];