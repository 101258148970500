export enum Color {
  primary = '#4063ec',
  secondary = '#9b9b9b',
  list = 'rgba(64,99,236,0.1)',
  white = '#FFFFFF',
  blackAlphaTest = 'rgba(0,0,0,0.85)',
  blackAlphaHalf = 'rgba(0,0,0,0.5)',
  black = '#222222',

  azul = '#4063ec',
  topaz = '#1ccdcc',
  green = '#10c771',
  red = '#ee1a1a',
  navy_light = '#6e58ff',
  navy_paling = '#6e58ff',
  navy = '#2b3294',
  darkBg = '#1f2437',

  gray = '#ccc',
  line = '#e0e0e0',
  light_gray = '#e2e2e2',
  warm_gray = '#707070',
  textGray = '#666',
  divider = '#E3E3F1',
  gray_sub_button = '#9b9b9b',
  light_gray02 = '#f5f5f5',
  brownish_gray = '#666',
  darkbg = '#1f2437',

  mainNavy = '#1f2437',
}

export enum Header {
  pc = '120px',
  mo = '60px',
}

export enum Breakpoint {
  mobile = '767px',
  desk1200 = '1200px', // 768~
  desk1280 = '1280px',
  desk1600 = '1600px',
  desk1920 = '1920px',
}
export enum Cursor {
  click = '/tsp/images/main/ico_mouse_click.png',
  drag = '/tsp/images/main/ico_mouse_drag.png',
  size = '40 40',
  auto = 'auto',
}