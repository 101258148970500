// 검색 라우트
import React from "react";
import {Loader, RouteType} from "shared/utils/RouteUtiles";

export const SearchRoute: RouteType[] = [
  {
    path: 'search',
    label: '검색',
    children: [
      {
        index: true,
        element: (
          <Loader
            route={{
              label: 'search',
              layout: 'empty',
              element: React.lazy(() => import('~/pages/Home/SearchEngine/SearchMain')),
            }}
          />
        ),
      },
      {
        path: 'result',
        label: '검색결과',
        element: (
          <Loader
            route={{
              label: 'searchResult',
              layout: 'empty',
              element: React.lazy(() => import('~/pages/Home/SearchEngine/SearchResult')),
            }}
          />
        ),
      },
    ],
  },
].map((route: Partial<RouteType>) => ({
  ...route,
  layout: 'space',
})) as RouteType[];