import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import Footer from "./Footer";
import {Stack} from "@mui/material";
import Top from "../../components/Top/Top";
import Portal from "../../components/Portal";
import {Header} from "./Header";
import {useLocation} from "react-router-dom";
import {useGlobalConfigStore} from "../../store/GlobalConfigStore";
import {useScroll} from "../../components/useScroll";
import {useScrollStore} from "../../store/ScrollStore";
import {isTspPortal} from "../../utils/validUtil";
import {useRouteStore} from "../../store/RouteConfigStore";
import {replace} from "ramda";

const Basic: React.FC<{}> = props => {
  const location = useLocation()
  const configStore = useGlobalConfigStore()
  const routeStore = useRouteStore()
  const scrollStore = useScrollStore()
  const [isShowFooter, setIsShowFooter] = useState(false);
  const {isContraction, scrollY} = useScroll()
  const [touchClientY, setTouchClientY] = useState(0)
  const [touchDirection,setTouchDirection] = useState<'up' | 'down'>('up')

  useEffect(() => {
    if (!scrollStore.isLocking) {
      if (scrollStore.isContraction != isContraction) {
        scrollStore.setContraction(isContraction)
      }
    }
  }, [isContraction])

  const init = () => {
    document.querySelector('body')!.classList.add('layout--basic');
    return () => {
      document.querySelector('body')!.classList.remove('layout--basic');
    };
  };
  useEffect(init, []);
  useEffect(() => {
    let rootPath = '/'
    switch (routeStore.portal){
      case 'PORTAL_USP': rootPath = '/'; break;
      case 'PORTAL_TSP': rootPath = '/tsp'; break;
      // case 'PORTAL_PMS': rootPath = '/biz'; break;
    }
    if (location.pathname.substring(0,4) == rootPath && location.pathname.length < 6) {
      setIsShowFooter(false)
    } else {
      configStore.setTopOpacity(false)
      setIsShowFooter(true)
    }
    scrollStore.setScrollDirection("up")
    window.scrollTo(0, 0);
  }, [location, routeStore.portal])

  const TouchMove = useCallback((e) => {
    const clientY = e.changedTouches[0].clientY
    if (!configStore.isDesktop) {
      if (touchClientY < clientY) {
        setTouchDirection("up")
      } else {
        setTouchDirection("down")
      }
      setTouchClientY(clientY)
    }
  }, [touchClientY])

  return <Stack
    justifyContent={"space-between"}
    style={{
      display: "flex",
      width: "100%",
      minHeight: "100vh"
    }}
    onWheel={(e) => {
      if (!scrollStore.isLocking)
        scrollStore.setScrollDirection(e.deltaY > 0 ? "down" : "up")
    }}
    onTouchMove={TouchMove}
    onTouchEnd={() => {
      scrollStore.setScrollDirection(touchDirection)
    }}
  >
    <Stack>
      <Header/>
      {props.children}
    </Stack>

    {isShowFooter && <Footer/>}

    <Portal>
      <Top/>
    </Portal>
  </Stack>
}

export default Basic;
