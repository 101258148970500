export enum Color {
  primary = '#4063ec',
  secondary = '#9b9b9b',
  list = 'rgba(64,99,236,0.1)',
  white = '#FFFFFF',
  blackAlphaTest = 'rgba(0,0,0,0.85)',
  black = '#222',

  azul = '#4063ec',
  topaz = '#1ccdcc',
  green = '#10c771',
  red = '#ee1a1a',
  navy_light = '#6e58ff',
  navy_paling = '#6e58ff',
  navy = '#2b3294',
  darkBg = '#1f2437',

  gray = '#ccc',
  line = '#e0e0e0',
  light_gray = '#e2e2e2',
  warm_gray = '#707070',
  textGray = '#666',
  divider = '#E3E3F1',
  gray_sub_button = '#9b9b9b',
  light_gray02 = '#f5f5f5',
  brownish_gray = '#666',
  darkbg = '#1f2437',

  dim40 = 'rgba(0,0,0,.4)',
  dim50 = 'rgba(0,0,0,.5)',

  bg_grey = '#f5f5f5',
  warm_grey = '#707070',
}
