import * as R from 'ramda';
import React, {Suspense, useEffect, useState} from 'react';
import {useRoutes, useLocation} from 'react-router-dom';
import NotFound from 'shared/NotFound';
import useSWR, {mutate} from 'swr';
import api from "./api";
import {Loader, middleware, RouteData, RouteType} from "./utils/RouteUtiles";
import {useRouteStore} from "./store/RouteConfigStore";
import {useQuery} from "react-query";
import {AxiosGet} from "./libs/axios";
import {reduce} from "ramda";
import {isTspPortal} from "./utils/validUtil";
import Error404Page from "./page/error/Error404Page";

export type PortalType = "PORTAL_UAM" | "PORTAL_TSP" | "PORTAL_TAM" | "PORTAL_USP" | "PORTAL_PMS";

interface IDynamicRouter {
  addRoutes?: RouteType[],
  portalType: PortalType,
}

interface Root {
  children: RouteData[]
}

export function DynamicRouter({addRoutes, portalType}: IDynamicRouter) {
  const [loading, setLoading] = useState(true)
  const [type, setType] = useState(portalType)
  const location = useLocation()
  const routeStore = useRouteStore()
  const url = `/member/api/auth/menus/${type}/me`
  const memberUrl = `${process.env.REACT_APP_DOMAIN_MEMBER_BNET}`

  const portal = useQuery(`portal_${type}`, () => AxiosGet(url, undefined, {
    baseURL: memberUrl
  }))

  useEffect(() => {
    routeStore.setRouteLoading(loading)
  }, [loading])

  useEffect(() => {
    if (location) {
      const [, path] = location.pathname.split('/');
      const menu: any =
        {
          biz: 'PORTAL_PMS',
          tsp: 'PORTAL_TSP'
        }[path] || 'PORTAL_USP';
      if (type != menu) {
        setType(menu)
        setLoading(true)
      }
    }
  }, [location])

  useEffect(() => {
    if (!portal.isLoading && !portal.isFetching) {
      if (!!portal.data) {
        const data = portal.data.list
        const routes = hierarchy(data)
        routeStore.setRoutes(routes)
        routeStore.setPortal(type)
        setLoading(false)
      }
    }
  }, [portal.data, portal.isLoading, portal.isFetching])

  if (loading) return <div/>

  return <Routes addRoutes={addRoutes} routes={routeStore.routes}/>;
}

const flatten = (routes: any) => {
  const res = routes.reduce((a: any, b: any) => {
    const {children = []} = b;
    const c = R.omit(['children'])(b);

    const d = flatten(children);

    return [...a, c, ...d];
  }, []);
  return res;
};

const adaptor = (routes: any) => {
  return routes.map((route: any) => {
    const {label, path} = route;
    const config = useRouteStore();
    const rootPath = isTspPortal ? '/tsp' : ''

    return {
      label,
      path: `${rootPath}${path}`,
      element: (
        <Loader
          route={middleware({
            layout: 'basic',
            //* path 컴포넌트
            element: React.lazy(() => import(`~/pages${path}`)),
          }, config.portal == 'PORTAL_PMS'? ['auth'] : [])}
        />
      ),
    };
  });
};


function Routes({routes, addRoutes}: any) {
  const [menus, setMenus] = useState(R.pipe(flatten, adaptor)(routes))
  // console.log('menus set!! ' + JSON.stringify(menus))
  const nav = useRoutes([
    ...addRoutes,
    ...menus,
    {
      path: '*',
      element: <Error404Page/>,
    },
  ]);

  return nav;
}

//* 1차원 배열을 계층 구조로 변환
function hierarchy(list: any) {

  //* 객체 복사
  const record: any[] = R.pipe(JSON.stringify, JSON.parse)(list);

  let map = record.reduce((a, b, i) => ({...a, [b.menuId]: i}), {});

  let root: Root = {children: []};

  //* 계층 생성
  record.forEach((item) => {
    item.path = `${item.url}`.replace(/\/\//g, '/');
    item.label = item.menuNm;
    if (item.parentMenuId === "ROOT") {
      root.children.push(item)
    } else if (!R.isNil(item.parentMenuId)) {
      // @ts-ignore
      const el = record[map[item.parentMenuId]];

      if (R.isNil(el.children)) el.children = [];

      el.children.push(item);
    }
  });

  return root.children;
}
