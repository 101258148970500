import { SvgIcon } from '@mui/material';

export namespace Icons{
  export const Exit = () => 
  <SvgIcon>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" enableBackground="new 0 0 24 24">  
    <image id="image0" width="24" height="24" x="0" y="0"
      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
      AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAGFBMVEVHcExtbW1wcHBwcHBw
      cHBwcHBwcHD///9Hvo08AAAAB3RSTlMAIYYQ/s/QgsBp6QAAAAFiS0dEBxZhiOsAAAAHdElNRQfm
      BxoKCBfH0LPNAAAAaklEQVQY02NgIAsIGYApIwEQqRIMIplDFECUmStIytQNrIA5JRgkkQzRBJKC
      SoCl4BIgKVGYBEjKJRlhlYiLAJzNHOISDOeYuom5GsAlksF2QSUMoM6AWg6TglgOlVJJhtilgOwf
      AwYiAQDSiBK9voIQegAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMi0wNy0yNlQxMDowODoyMyswMDow
      MBJKYXgAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjItMDctMjZUMTA6MDg6MjMrMDA6MDBjF9nEAAAA
      AElFTkSuQmCC" />
    </svg>
  </SvgIcon>
}