import styled from '@emotion/styled';
import useSWR from 'swr';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import {RouteData, RouteType} from '../../utils/RouteUtiles';
import {Box, Button, Collapse, Drawer, keyframes, List, ListItemButton, ListItemText, Stack} from '@mui/material';
import {useGlobalConfigStore} from '../../store/GlobalConfigStore';
import React, {Dispatch, Fragment, SetStateAction, useState} from 'react';
import {Body2, Body3, Body4} from '../../components/TextComponents';
import {Icons} from '../../components/IconContainer';
import {HorizontalInterval, VerticalInterval} from '../../components/LayoutComponents';
import {Color} from '../../components/StyleUtils';
import {useScrollStore} from '../../store/ScrollStore';
import {useRouteStore} from '../../store/RouteConfigStore';
import {isTspPortal} from "../../utils/validUtil";
import {PortalType} from "../../DynamicRouter";
import {AuthenticationType, getUserNm} from "../../authentication";
import authentication from "../../authentication";

export const TopNavigationBar: React.FC<{
  isDesktop: boolean;
  isShortBar: boolean;
  isLeftDrawer: boolean;
  isSmallMode: boolean;
  setIsLeftDrawer: Dispatch<SetStateAction<boolean>>;
  navContentRef: React.Ref<HTMLElement>;
  scrollDirection: 'down' | 'up';
  scrollY: number;
}> = React.forwardRef((props) => {
  const {isTopOpacity} = useGlobalConfigStore();
  const scrollStore = useScrollStore();
  // const {data: routes = []} = useSWR('route://service')
  const {routes, portal} = useRouteStore();
  const header = document.querySelector('header');
  const html = document.querySelector('html');
  const opacity = props.isLeftDrawer ? false : isTopOpacity;

  const handlerMenuButton = () => {
    const isActive = !props.isLeftDrawer;

    scrollStore.setScrollDirection(isActive ? 'down' : 'up');
    scrollStore.setContraction(
      isActive ? true : props.scrollY == 0 ? false : true
    );
    scrollStore.setLocking(isActive);
    if (header)
      header.style['zIndex'] = isActive && props.isDesktop ? '1300' : '5';
    if (html) html.style['overflow'] = isActive ? 'hidden' : 'auto';
    props.setIsLeftDrawer(isActive);
  };

  if (routes.length == 0) return <div></div>;

  return (
    <NavigationBarContainer
      ref={props.navContentRef}
      isOpacity={opacity}
      isDesktop={props.isDesktop}
      isShortBar={props.isShortBar}
      scrollDirection={props.scrollDirection}
      scrollY={props.isLeftDrawer ? 1 : props.scrollY}
      isLeftDrawer={props.isLeftDrawer}
    >
      {props.isDesktop ? (
        <DesktopNavigationBar
          {...props}
          portal={portal}
          routes={routes}
          isTopOpacity={opacity}
          isLeftDrawer={props.isLeftDrawer}
          handlerMenuButton={handlerMenuButton}
        />
      ) : (
        <MobileNavigationBar
          isTopOpacity={opacity}
          portal={portal}
          isLeftDrawer={props.isLeftDrawer}
          handlerMenuButton={handlerMenuButton}
        />
      )}
      <LeftDrawer
        open={props.isLeftDrawer}
        isDesktop={props.isDesktop}
        isOpacity={opacity}
        routes={routes}
        onClose={handlerMenuButton}
      />
    </NavigationBarContainer>
  );
});

const MobileNavigationBar = (props: {
  isTopOpacity: boolean;
  isLeftDrawer: boolean;
  portal: PortalType
  handlerMenuButton: () => void;
}) => {
  const searchColor = isTspPortal? Color.navy_light : Color.topaz;
  let title = '사용자지원포털'
  let navi = '/'
  switch (props.portal) {
    case "PORTAL_TSP":
      title = '실증지원포털';
      navi = '/tsp';
      break;
    case "PORTAL_PMS":
      title = '사업관리';
      navi = '/biz';
      break;
  }
  
  return (
    <Fragment>
      <Button sx={{position: 'absolute'}} onClick={props.handlerMenuButton}>
        {props.isLeftDrawer ? (
          <Icons.LeftArrow/>
        ) : (
          <Icons.Menu white={props.isTopOpacity}/>
        )}
      </Button>

      <Box sx={{display: 'flex', width: '100%', justifyContent: 'center'}}>
        {
          (props.portal === 'PORTAL_USP' || props.portal === 'PORTAL_PMS')
          ? (
            <div className="mob_home">
              <NavLink to="/">
                <img
                  alt={'logoIcon'}
                  src={
                    props.isTopOpacity
                      ? '/images/img/white_logo.png'
                      : '/images/img/color_logo.png'
                  }
                />
              </NavLink>
              <h1>
              <span className="mob_tit">
              <NavLink to={navi}>
              {title}
              </NavLink>
              </span>
              </h1>
            </div>
            )
          : (
              <NavLink to="/tsp">
                <img
                  alt={'logoIcon'}
                  src={
                    props.isTopOpacity
                      ? '/tsp/images/img/white_logo.png'
                      : '/tsp/images/img/color_logo.png'
                  }
                />
              </NavLink>
            )
        }
      </Box>

      <SearchBox size={60} backgroundColor={searchColor}
      onClick={() => {
        window.open(`${process.env.REACT_APP_DOMAIN}/search`, "_blank")
      }}>
        <Icons.GNBSearch/>
      </SearchBox>
    </Fragment>
  );
};

const DesktopNavigationBar = (props: {
  portal: PortalType
  isSmallMode: boolean;
  isShortBar: boolean;
  isTopOpacity: boolean;
  isLeftDrawer: boolean;
  routes: RouteData[];
  handlerMenuButton: () => void;
  scrollDirection: 'up' | 'down';
}) => {
  const location = useLocation();
  const {setPortal, setRoutes} = useRouteStore()
  const slice = isTspPortal ? 4 : 0
  const items = getActivateRoutes(location.pathname.substring(slice), props.routes);
  let activeLabel = '';
  if (items && items.length > 0) {
    activeLabel = items[0].label;
  }

  const rootPath = isTspPortal ? '/tsp' : ''
  let title = '사용자지원포털'
  let navi = '/'
  let searchColor = Color.topaz
  switch (props.portal) {
    case "PORTAL_TSP":
      title = '실증지원포털';
      navi = '/tsp';
      searchColor = Color.navy_light
      break;
    case "PORTAL_PMS":
      title = '사업관리';
      navi = '/biz';
      break;
  }

  return (
    <Fragment>
      {
        <Box className="sidemenu">
          <Button className={'menu-icon'} sx={{position: 'absolute'}} onClick={props.handlerMenuButton}>
            {props.isLeftDrawer ? (
              <Icons.SideArrow/>
              // 1200일때 아이콘
            ) : (
              <Icons.Menu white={props.isTopOpacity}/>
            )}
          </Button>
          <h1>
            <NavLink to={navi}>
              <Logo
                url={
                  props.isTopOpacity
                    ? `${rootPath}/images/img/white_logo.png`
                    : `${rootPath}/images/img/color_logo.png`
                }
              >AICA</Logo>
            </NavLink>
            <span className="loc_tit">
              <NavLink to={navi}>
              {title}
              </NavLink>
            </span>
          </h1>
        </Box>
      }
      {props.isSmallMode && (
        <NavLink
          style={{display: 'flex', width: '100%', justifyContent: 'center'}}
          to={navi}
        >
          <Logo
            url={
              props.isTopOpacity
                ? `${rootPath}/images/img/white_logo.png`
                : `${rootPath}/images/img/color_logo.png`
            }
          />
        </NavLink>
      )}
      {/*{props.isSmallMode || <Box sx={{display: 'flex', flex: 1}}/>}*/}

      {props.isSmallMode || (
        <Box className={`menu ${props.portal == "PORTAL_PMS"? 'biz' : ''}`}>
          {props.routes.filter(f => f.readYn).map((m: RouteType, i: number) => {
            const active = activeLabel == m.label;
            return (
              <li key={i}>
                <Button type={'button'} className={active ? 'active' : ''}>
                  <Body2 weight={500}>{m.label}</Body2>
                </Button>
                <ul>
                  {(m.children || []).filter(f => f.readYn && f.label != '사업신청').map((depth2: RouteType, j: number) => {
                    return (
                      <li key={j}>
                        <NavLink to={`${rootPath}${depth2.path}`}>
                          {depth2.label}
                        </NavLink>
                        {(depth2.children || []).map(
                          (depth3: RouteType, k: number) => {
                            return (
                              <NavLink key={k} to={`${depth3.path}`}>
                                {depth3.label}
                              </NavLink>
                            );
                          }
                        )}
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </Box>
      )}

      {/*{props.isSmallMode || <Box sx={{display: 'flex', flex: 2}}/>}*/}
      {
        props.portal == "PORTAL_USP" && !props.isSmallMode &&
        <QuieckMovePage direction={'row'} spacing={'20px'} opacity={props.isTopOpacity? 1 : 0}>
          <NavLink to="/biz" onClick={(e) => {
            // setPortal("PORTAL_PMS")
            // setRoutes([])
          }}>
            <span>사업관리</span>
          </NavLink>
          {/*<NavLink to="" onClick={(e) => {*/}
          {/*  if (!!!authentication.getToken()) {*/}
          {/*    window.location.href = `${process.env.REACT_APP_DOMAIN}/lms`*/}
          {/*  }else {*/}
          {/*    window.location.href = `${process.env.REACT_APP_LMS_URL}`*/}
          {/*  }*/}
          {/*  // window.location.href = `${process.env.REACT_APP_DOMAIN}/lms`*/}
          {/*}}>*/}
          {/*  <span>AI 융합 아카데미</span>*/}
          {/*</NavLink>*/}
        </QuieckMovePage>
      }
      <SearchBox
        size={props.isShortBar && props.scrollDirection == 'down' ? 60 : 80}
        backgroundColor={searchColor}
        onClick={() => {
          window.open(`${process.env.REACT_APP_DOMAIN}/search`, "_blank")
        }}
      >
        <Icons.GNBSearch/>
      </SearchBox>
    </Fragment>
  );
};

function getActivateRoutes(
  pathname: string,
  items: RouteType[],
  selected = []
): any {
  return items.reduce((a: any, b: RouteType) => {
    const regexp = new RegExp(`^${b.path!}`)
    if (regexp.test(pathname)) a.push(b)
    return getActivateRoutes(pathname, b.children || [], a)
  }, selected);
}

const PortalBlock = (props: {
  icon: JSX.Element;
  OnClick: () => void;
  label: string;
}) => {
  return (
    <Stack onClick={props.OnClick} spacing={1}>
      {props.icon}
      <Body4 center preLine>
        {props.label}
      </Body4>
    </Stack>
  );
};

const LeftDrawer = (props: {
  open?: boolean;
  isDesktop: boolean;
  isOpacity: boolean;
  routes: RouteData[];
  onClose: () => void;
}) => {
  const {data} = useSWR<AuthenticationType>('authentication');
  const [selected, setSelected] = useState<string[]>([]);
  const navigate = useNavigate();
  const topGap = props.isDesktop ? '60px' : '0px';
  const rootPath = isTspPortal ? '/tsp' : ''
  const isLogin = !!data?.accessToken

  const handlerCheck = (label: string) => {
    const selectedIndex = selected.indexOf(label);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, label);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const FindNavList = (selectedLabel: string) => {
    const route = props.routes.find((f) => f.menuNm == selectedLabel);
    if (route && route?.children) {
      return route.children.filter(f => f.readYn).map((m) => {
        return {menuNm: m.menuNm, path: m.path};
      });
    }

    return [];
  };

  return (
    <Drawer
      sx={{
        overflow: 'auto',
        '.MuiBackdrop-root': {
          top: topGap,
        },
      }}
      PaperProps={{
        sx: {
          width: props.isDesktop ? '375px' : '100%',
          minWidth: '375px',
          top: topGap,
          height: `calc(100% - ${topGap})`,
          boxShadow: 'none',
        },
      }}
      anchor={'left'}
      open={props.open}
      onClose={props.onClose}
    >
      {!props.isDesktop && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingY: '16px',
          }}
        >
          <Box
            sx={{position: 'absolute', left: '15px'}}
            onClick={props.onClose}
          >
            <Icons.SideArrow/>
          </Box>
          <Stack alignItems={'center'}>
            <Body2 bold>{isTspPortal ? '실증지원포털' : '사용자지원포털'}</Body2>
          </Stack>
        </Box>
      )}
      <Stack
        direction={'row'}
        padding={props.isDesktop ? '40px 25px 30px' : '24px 25px' }
        justifyContent={'space-between'}
      >
        <PortalBlock
          icon={<Icons.USPPortal/>}
          label={'사용자\n지원포털'}
          OnClick={() => {
            window.location.href = `${process.env.REACT_APP_DOMAIN}`
          }}
        />
        <PortalBlock
          icon={<Icons.TSPPortal/>}
          label={'실증\n지원포털'}
          OnClick={() => {
            window.location.href = `${process.env.REACT_APP_DOMAIN}/tsp`
          }}
        />
        <PortalBlock
          icon={<Icons.DXPPortal/>}
          label={'데이터\n유통포털'}
          OnClick={() => {
            window.location.href = `${process.env.REACT_APP_DOMAIN}/dxp`
          }}
        />
        <PortalBlock
          icon={<Icons.SAZPortal/>}
          label={'안심구역\n포털'}
          OnClick={() => {
            window.location.href = `${process.env.REACT_APP_DOMAIN}/saz`
          }}
        />
        <PortalBlock
          icon={<Icons.LMSPortal/>}
          label={'AI 융합\n아카데미'}
          OnClick={() => {
            window.location.href = `${process.env.REACT_APP_DOMAIN}/lms`
          }}
        />
        <PortalBlock
          icon={<Icons.OriginPortal/>}
          label={'사업단\n홈페이지'}
          OnClick={() => {
            window.location.href = `http://www.aica-gj.kr/main.php`
          }}
        />
        <PortalBlock
          icon={<Icons.ComCorpPortal/>}
          label={'AI기업\n협력센터'}
          OnClick={() => {
            window.location.href = `http://ai365.or.kr/`
          }}
        />
      </Stack>
      <Box
        sx={{
          display: 'flex',
          // minHeight: '8px',
          width: '100%',
          backgroundColor: Color.light_gray02,
        }}
      >
        {
          !isTspPortal &&
          <LeftMenuQuieckPage direction={'row'} spacing={'20px'} >
            <NavLink to="/biz" onClick={(e) => {
              // setPortal("PORTAL_PMS")
              // setRoutes([])
            }}>
              <Body3 center color={Color.warm_gray}>
                사업관리
              </Body3>
            </NavLink>
            {/*<Box sx={{borderLeft: `1px solid ${Color.gray}`, height: '14px'}}/>*/}
            {/*<NavLink to="#!">*/}
            {/*  <Body3 center color={Color.warm_gray}>*/}
            {/*    AI 융합 아카데미*/}
            {/*  </Body3>*/}
            {/*</NavLink>*/}
          </LeftMenuQuieckPage>
        }
      </Box>
      <List sx={{width: '100%', padding: '8px 0'}}>
        {props.routes &&
          props.routes.filter(f => f.readYn).map((m, i) => {
            const route = FindNavList(m.menuNm);
            const isActive = selected.includes(m.menuNm);
            return (
              <Stack key={'route-' + i}>
                <ListItemButton
                  key={i}
                  sx={{padding: '16px 25px !important'}}
                  onClick={() => {
                    handlerCheck(m.menuNm);
                  }}
                >
                  <ListItemText sx={{'> span' : { fontSize: props.isDesktop ? '16px' : '14px', fontWeight: 'bold' } , margin: 0}} primary={m.menuNm}/>
                  <Box
                    sx={{
                      transform: !isActive ? 'rotate(180deg)' : 'rotate(0)',
                      transition: '0.5s',
                      opacity: '0.4'
                    }}
                  >
                    <Icons.UpArrow/>
                  </Box>
                </ListItemButton>
                {
                  <Collapse
                    in={isActive}
                    key={`Collapse-${i}`}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        rowGap: '15px',
                        marginX: '15px',
                        padding: '24px',
                        backgroundColor: '#edeff6',
                        borderRadius: '10px',
                      }}
                    >
                      {route.map((nav, j) => {
                        return (
                          <Button
                            key={'child-'+j}
                            sx={{
                              padding: 0,
                              justifyContent: 'flex-start',
                              '&:hover': {
                                backgroundColor: '#edeff6',
                                '> span:first-child': {
                                  borderBottom: '1px solid #222',
                                  marginBottom: '-1px' 
                                  // boxShadow : 'inset 0 -1px 0 1px #222'
                                  // textDecoration: 'underline',
                                },
                              },
                            }}
                            onClick={() => {
                              navigate(`${rootPath}${nav.path}`);
                              setSelected([]);
                              props.onClose();
                            }}
                          >
                            <Body3 weight={500}>{nav.menuNm}</Body3>
                          </Button>
                        );
                      })}
                    </Box>
                  </Collapse>
                }
              </Stack>
            );
          })}
      </List>

      <VerticalInterval size={'20px'}/>
      <Stack
        direction={'row'}
        justifyContent={'space-evenly'}
        alignItems={'center'}
        sx={{
          marginX: '15px',
          paddingX: '24px',
          minHeight: '60px',
          backgroundColor: Color.light_gray02,
          borderRadius: '10px',
        }}
      >
        {isLogin && (
          <Fragment>
            <Box>
              <Body4 weight={500}>{getUserNm()}</Body4>
              <Body4>{'님, 안녕하세요'}</Body4>
            </Box>
            <Box
              sx={{
                fontSize: '12px',
                color: Color.warm_gray,
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
              component={'button'}
              onClick={()=>{navigate(`${isTspPortal? '/tsp' : '/signin'}/signout`)}}
            >
              {'로그아웃'}
            </Box>
          </Fragment>
        )}
        {isLogin || (
          <Fragment>
            <NavLink to={`${rootPath}/signin`} state={{nextUrl: window.location.href}}>
              <Body3 weight={500}>로그인</Body3>
            </NavLink>
            <Box sx={{borderLeft: `1px solid ${Color.gray}`, height: '14px'}}/>
            <NavLink to={`${rootPath}/signup`} onClick={() => {
              if(isTspPortal) {
                window.location.href = `${process.env.REACT_APP_DOMAIN}/signup`
              }
            }}>
              <Body3 weight={500}>회원가입</Body3>
            </NavLink>
          </Fragment>
        )}
      </Stack>
    </Drawer>
  );
};


export const Logo = styled('div')<{ url: string }>`
  background: url(${(props) => props.url}) no-repeat;
  display: block;
  width: 100px;
  height: 22px;
  text-indent: -9999px;
`;

const menuAni = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, -10px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
`;

const menuBounce = keyframes`
  0% {
    opacity: 0;
    top: 20px;
  }
  50% {
    top: -5px;
  }
  70% {
    opacity: 1;
    top: 10px;
  }
  100% {
    top: 0;
  }
`;
const NavigationBarContainer = styled('section')<{
  isOpacity: boolean;
  isDesktop: boolean;
  isShortBar: boolean;
  scrollDirection: 'down' | 'up';
  scrollY: number;
  isLeftDrawer: boolean;
}>`
  position: relative;
  display: flex;
  width: 100%;
  top: ${(props) =>
          props.scrollDirection == 'up' || props.scrollY == 0
                  ? 0
                  : !props.isDesktop
                          ? -60
                          : props.isShortBar || props.isLeftDrawer
                                  ? -40
                                  : 0}px;
  height: ${(props) =>
          props.isDesktop && (!props.isShortBar || props.scrollDirection == 'up')
                  ? 80
                  : 60}px;
  align-items: center;
    /* border-bottom: 1px solid ${(props) =>
          props.isOpacity ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)'}; */
  background-color: ${(props) => (props.isOpacity ? 'none' : '#fff')};
  box-shadow: ${(props) =>
          props.isOpacity
                  ? 'inset 0 -1px 0 0 rgba(204, 204, 204, 0.1)'
                  : '0 3px 6px 0 rgba(0, 0, 0, 0.2)'};
  z-index: ${(props) => (props.isLeftDrawer ? 1300 : 999)};
  transition: 0.3s;

  @media screen and (max-width: 767px) {
    box-shadow: ${(props) =>
            props.isOpacity
                    ? 'inset 0 -1px 0 0 rgba(204, 204, 204, 0.1)'
                    : 'inset 0 -1px 0 0 rgba(204, 204, 204, 0.2)'};
  top: ${(props) =>
          props.scrollDirection == 'up' || props.scrollY == 0
                  ? 0
                  : !props.isDesktop
                          ? 0
                          : props.isShortBar || props.isLeftDrawer
                                  ? 0
                                  : 0}px;
  > .MuiButtonBase-root{
    position: absolute;
  }
  
  }
  //justify-content: space-between;
  //padding: 22px 40px;
  .mob_home {
    display: flex;
    flex-shrink: 0;
    height: 100%;
    align-items: center;
    color: ${(props) => (props.isOpacity ? '#fff' : '#000000')};
    h1 {
      display: flex;
      margin: 0;
      align-items: center;

      .mob_tit {
        white-space: nowrap;
        margin-left: 16px;
        font-size: 19px;
        vertical-align: center;
        letter-spacing: -0.38px;
        line-height: 28px;
      }
    }
  }

  .sidemenu {
    position: absolute;
    display: flex;
    flex-shrink: 0;
    margin-left: 30px;
    height: 100%;
    align-items: center;
    color: ${(props) => (props.isOpacity ? '#fff' : '#000000')};

    > button {
      margin-right: 10px;
      padding: 0;
      width: 24px;
    }

    h1 {
      display: flex;
      margin: 0;
      align-items: center;

      a {
        display: inline-block;
        vertical-align: middle;
        vertical-align: top;

        &:focus-visible {
          outline: 2px solid ${props => props.isOpacity ? '#fff' : '#000'};
        }
      }

      .loc_tit {
        white-space: nowrap;
        margin-left: 16px;
        font-size: 19px;
        vertical-align: center;
        letter-spacing: -0.38px;
        line-height: 28px;
      }
    }

    .menu-icon {
      display: none;
      @media screen and (max-width: 1200px) {
        display: flex;
        position: relative;
      }
    }
  }

  .nav {
    width: 100%;
    max-width: 750px;
    font-size: 18px;
    letter-spacing: -0.2px;
    z-index: 999;
    flex: 0 0 50%;
  }

  .menu {
    //position: absolute;
    //left: 50%;
    //transform: translate(-50%, 0);
    display: flex;
    flex-shrink: 0;
    width: 100%;
    justify-content: center;
    //justify-content: center;
    li {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: left;
      margin: 0 12px;
      .MuiButton-root {
        padding: 10px 7px;
        font-size: 18px;
        font-family: Noto Sans CJK KR;
        white-space: nowrap;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.36px;
        background-color: rgba(0, 0, 0, 0);
        text-align: center;
        box-shadow: none;
        //margin-right: 26px;

        > span {
          color: ${(props) => (props.isOpacity ? '#fff' : '#000000')};

          &:hover {
            color: ${(props) => (props.isOpacity ? '#fff' : '#4063ec')};
          }
        }
      }

      .active:not(:hover) {
        > span {
          color: #4063ec;

          &:after {
            content: '';
            width: 5px;
            height: 5px;
            display: block;
            background-color: #4063ec;
            border-radius: 5px;
            position: absolute;
            top: 0;
            left: 50%;
            margin-left: -2px;
          }
        }
      }

      > ul {
        display: none;

        li {
          flex-direction: column;
          text-align: center;
          font-weight: 400;
        }
      }

      &:hover {
        .MuiButton-root {
          color: #4063ec;
          //font-weight: bold;
          &:after {
            content: '';
            width: 5px;
            height: 5px;
            display: block;
            background-color: ${(props) =>
                    props.isOpacity ? '#fff' : '#4063ec'};
            border-radius: 5px;
            position: absolute;
            top: -2px;
            left: 50%;
            margin-left: -2px;
            animation: ${menuBounce} 0.7s linear forwards;
          }
        }

        > ul {
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 40px;
          left: 50%;
          transform: translateX(-50%);
          background-color: #fff;
          height: auto;
          justify-content: center;
          min-width: 160px;
          border-radius: 10px;
          padding: 10px 20px;
          font-size: 16px;
          box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
          animation: ${menuAni} 0.5s;
          //opacity: 1;

          > li {
            line-height: 1.6;
            margin: 5px;
            flex: initial;
            white-space: nowrap;

            &:hover {
              color: #4063ec;
              text-decoration: underline;
            }
          }
        }
      }
    }

    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
  
  .biz {
    justify-content: flex-end;
    padding-right: 110px;
  }
`;

const QuieckMovePage = styled(Stack)<{
  opacity: number;
}>`
  position: absolute;
  top: 50%;
  right: 110px;
  display: flex;
  transform: translateY(-50%);
  
  > a {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: ${(props) =>
            props.opacity == 1 ? '#fff' : '#707070'};
    line-height: 24px;
    letter-spacing: -0.06em;

    &::after {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      color: ${(props) => props.opacity == 1? '#fff' : '#707070'};
      /* background: url('/images/main/icon_pagelink.svg') no-repeat center / contain; */
      background: no-repeat center / contain;
      background-image: url(/images/main/${(props) => props.opacity == 1? 'ico_new-window_white.svg' : 'icon_pagelink.svg'});
    }

    & + a {
      margin-left: 20px;
    }

    &:focus-visible {
      outline: 2px solid ${props => props.opacity === 0 ? '#000' : '#fff'};
    }
  }
`;

const LeftMenuQuieckPage = styled(Stack)`
  padding: 15px 58px;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  display: flex;
  min-height: 50px;
  
  > a {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: #707070;
    line-height: 24px;
    letter-spacing: -0.06em;

    &::after {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      color: #707070;
      margin-left: 2px;
      background: url('/images/main/icon_pagelink.svg') no-repeat center /
        contain;
    }

    & + a {
      margin-left: 20px;
    }
  }
`;

const SearchBox = styled(Box)<{ size: number, backgroundColor: string }>`
  position: absolute;
  right: 0;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  display: flex;
  min-width: ${(props) => props.size}px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
  background-color: alpha(#fff, 0.15);
  transition: 0.3s;

  &:hover {
    background-color: alpha(#fff, 0.25);
  }
`;
