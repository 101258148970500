// 상세페이지 라우트
import React from "react";
import {Loader, middleware, RouteType} from "shared/utils/RouteUtiles";

export const DetailPagesRoutes: RouteType[] = [
  {
    path: '/Notice/Notice/:id',
    label: '모집공고 상세',
    element: (
      <Loader
        route={{
          label: 'NoticeDetall',
          layout: 'basic',
          element: React.lazy(() => import('~/pages/Notice/View/NoticeDetall')),
        }}
      />
    ),
  },
  {
    path: '/Notice/Announcement/:id',
    label: '공지사항상세페이지',
    element: (
      <Loader
        route={{
          label: 'announcement',
          layout: 'basic',
          element: React.lazy(
            () => import('~/pages/Notice/View/AnnouncementDetail')
          ),
        }}
      />
    ),
  },
  {
    path: '/Notice/AnnouncementSelectionRes/:id',
    label: '선정결과공고상세페이지',
    element: (
      <Loader
        route={{
          label: 'announcement',
          layout: 'basic',
          element: React.lazy(
            () => import('~/pages/Notice/View/AnnouncementSelectionResDetail')
          ),
        }}
      />
    ),
  },
  {
    path: '/SupportForUse/ReferenceRoom/:id',
    label: '자료실상세페이지',
    element: (
      <Loader
        route={{
          label: 'reference',
          layout: 'basic',
          element: React.lazy(
            () => import('~/pages/SupportForUse/View/ReferenceRoomDetail')
          ),
        }}
      />
    ),
  },
  {
    path: '/MyPage/InquiryMmt/OneByOneMmt/:id',
    label: '1:1문의상세',
    element: (
      <Loader
        route={{
          label: 'OneByOneMmtDetail',
          layout: 'basic',
          element: React.lazy(
            () => import('~/pages/MyPage/InquiryMmt/OneByOneMmtDetail')
          ),
        }}
      />
    ),
  },
  {
    path: '/EventNews/HonsaEvent/:id',
    label: '행사/이벤트 상세',
    element: (
      <Loader
        route={{
          label: 'honsa',
          layout: 'basic',
          element: React.lazy(
            () => import('~/pages/EventNews/View/HonsaEventDetail')
          ),
        }}
      />
    ),
  },
  {
    path: '/MyPage/UsageMmt/TreadmillMmt/:id',
    label: '디딤널상세',
    element: (
      <Loader
        route={{
          label: 'treadmill',
          layout: 'basic',
          element: React.lazy(
            () => import('~/pages/MyPage/UsageMmt/TreadmillMmtDetail')
          ),
        }}
      />
    ),
  },
  {
    path: '/MyPage/UsageMmt/FacilityReservationMmt/:id',
    label: '시설예약상세',
    element: (
      <Loader
        route={{
          label: 'reservation',
          layout: 'basic',
          element: React.lazy(
            () => import('~/pages/MyPage/UsageMmt/FacilityReservationMmtDetail')
          ),
        }}
      />
    ),
  },
  {
    path: '/EventNews/ResInfoSharing/:id',
    label: '자유게시판 상세',
    element: (
      <Loader
        route={{
          label: 'resource',
          layout: 'basic',
          element: React.lazy(
            () => import('~/pages/EventNews/View/ResInfoSharingDetail')
          ),
        }}
      />
    ),
  },
  {
    path: 'ResourceInfoSharing',
    label: '자유게시판 의견작성',
    element: (
      <Loader
        route={{
          label: 'resource',
          layout: 'basic',
          element: React.lazy(
            () => import('~/pages/EventNews/ResourceInfoSharing')
          ),
        }}
      />
    ),
  },
  {
    path: '/SupportForUse/UserManual/:id',
    label: '사용자매뉴얼상세',
    element: (
      <Loader
        route={{
          label: 'manual',
          layout: 'basic',
          element: React.lazy(
            () => import('~/pages/SupportForUse/View/UserManualDetail')
          ),
        }}
      />
    ),
  },
  {
    path: '/SupportForUse/FrequentlyAskedQuestions/:id',
    label: '자주묻는질문',
    element: (
      <Loader
        route={{
          label: 'question',
          layout: 'basic',
          element: React.lazy(
            () =>
              import(
                '~/pages/SupportForUse/View/FrequentlyAskedQuestionsDetail'
                )
          ),
        }}
      />
    ),
  },
  {
    path: '/Community/IntroductionBusGroup/:id',
    label: '시설예약상세페이지',
    element: (
      <Loader
        route={{
          label: 'reservation',
          layout: 'basic',
          element: React.lazy(
            () => import('~/pages/Community/IntroductionBusGroupDetail')
          ),
        }}
      />
    ),
  },
  {
    path: '/Community/ReservationOne/:id',
    label: '시설예약시간선택페이지',
    element: (
      <Loader
        route={{
          label: 'reservation',
          layout: 'basic',
          element: React.lazy(() => import('~/pages/Community/ReservationOne')),
        }}
      />
    ),
  },
  {
    path: '/Community/ReservationTwo/:id',
    label: '시설예약신청페이지',
    element: (
      <Loader
        route={middleware(
          {
            label: 'reservation',
            layout: 'basic',
            element: React.lazy(
              () => import('~/pages/Community/ReservationTwo')
            ),
          },
          ['auth']
        )}
      />
    ),
  },
  {
    path: '/SupportForUse/ExpertApplicationDetail01',
    label: '전문가신청(신청자정보)',
    element: (
      <Loader
        route={{
          label: 'expert',
          layout: 'basic',
          element: React.lazy(
            () => import('~/pages/SupportForUse/View/ExpertApplicationDetail01')
          ),
        }}
      />
    ),
  },
  {
    path: '/SupportForUse/ExpertApplicationDetail02',
    label: '전문가신청(커리어정보)',
    element: (
      <Loader
        route={{
          label: 'expert',
          layout: 'basic',
          element: React.lazy(
            () => import('~/pages/SupportForUse/View/ExpertApplicationDetail02')
          ),
        }}
      />
    ),
  },
  {
    path: '/SupportForUse/ExpertApplicationDetail03',
    label: '전문가신청(신청완료)',
    element: (
      <Loader
        route={{
          label: 'expert',
          layout: 'basic',
          element: React.lazy(
            () => import('~/pages/SupportForUse/View/ExpertApplicationDetail03')
          ),
        }}
      />
    ),
  },
  {
    path: '/biz/BusinessAppMgt/BusinessApp/:id',
    label: '신청체크리스트',
    element: (
      <Loader
        route={middleware({
          label: 'biz',
          layout: 'basic',
          element: React.lazy(
            () => import('~/pages/biz/BusinessAppMgt/BusinessApp')
          ),
        },['auth'])}
      />
    ),
  },
  {
    path: '/biz/BusinessAppMgt/BusinessAppInfo/:id',
    label: '신청자정보',
    element: (
      <Loader
        route={middleware({
          label: 'biz',
          layout: 'basic',
          element: React.lazy(
            () => import('~/pages/biz/BusinessAppMgt/BusinessAppInfo')
          ),
        },['auth'])}
      />
    ),
  },
  {
    path: '/biz/BusinessAppMgt/BusinessAppConfirmInfo/:id',
    label: '신청정보',
    element: (
      <Loader
        route={middleware({
          label: 'biz',
          layout: 'basic',
          element: React.lazy(
            () => import('~/pages/biz/BusinessAppMgt/BusinessAppConfirmInfo')
          ),
        },['auth'])}
      />
    ),
  },
  {
    path: '/biz/BusinessAppMgt/BusAppMgtDetail/:id',
    label: '사업신청 관리 상세',
    element: (
      <Loader
        route={middleware({
          label: 'biz',
          layout: 'basic',
          element: React.lazy(
            () => import('~/pages/biz/BusinessAppMgt/BusAppMgtDetail')
          ),
        },["auth"])}
      />
    ),
  },
  {
    path: '/biz/EvaluationMgt/SubmissionMaterials/:id',
    label: '발표자료 제출',
    element: (
      <Loader
        route={middleware({
          label: 'biz',
          layout: 'basic',
          element: React.lazy(
            () => import('~/pages/biz/EvaluationMgt/View/SubmissionMaterials')
          ),
        },['auth'])}
      />
    ),
  },
  {
    path: '/biz/ContractMgt/BusinessPlanMgtDetail/:id',
    label: '사업계획서관리 상세',
    element: (
      <Loader
        route={middleware({
          label: 'biz',
          layout: 'basic',
          element: React.lazy(
            () => import('~/pages/biz/ContractMgt/View/BusinessPlanMgtDetail')
          ),
        },['auth'])}
      />
    ),
  },
  {
    path: '/biz/ContractMgt/ElectronicAgtMgtDetail/:id',
    label: '전자협약관리 상세',
    element: (
      <Loader
        route={middleware({
          label: 'biz',
          layout: 'basic',
          element: React.lazy(
            () => import('~/pages/biz/ContractMgt/View/ElectronicAgtMgtDetail')
          ),
        },['auth'])}
      />
    ),
  },
  {
    path: '/biz/ContractMgt/AgreementChangeMgtApp/:id',
    label: '협약변경관리 신청',
    element: (
      <Loader
        route={middleware({
          label: 'biz',
          layout: 'basic',
          element: React.lazy(
            () => import('~/pages/biz/ContractMgt/View/AgreementChangeMgtApp')
          ),
        },['auth'])}
      />
    ),
  },
  {
    path: '/biz/ContractMgt/AgreementChangeMgtApp00/:id',
    label: '협약변경관리 신청 과제정보',
    element: (
      <Loader
        route={middleware({
          label: 'biz',
          layout: 'basic',
          element: React.lazy(
            () => import('~/pages/biz/ContractMgt/AgreementChangeMgtApp00')
          ),
        },['auth'])}
      />
    ),
  },
  {
    path: '/biz/ContractMgt/AgreementChangeMgtApp01/:id',
    label: '협약변경관리 신청 참여기업',
    element: (
      <Loader
        route={middleware({
          label: 'biz',
          layout: 'basic',
          element: React.lazy(
            () => import('~/pages/biz/ContractMgt/AgreementChangeMgtApp01')
          ),
        },['auth'])}
      />
    ),
  },
  {
    path: '/biz/ContractMgt/AgreementChangeMgtApp02/:id',
    label: '협약변경관리 신청 참여인력',
    element: (
      <Loader
        route={middleware({
          label: 'biz',
          layout: 'basic',
          element: React.lazy(
            () => import('~/pages/biz/ContractMgt/AgreementChangeMgtApp02')
          ),
        },['auth'])}
      />
    ),
  },
  {
    path: '/biz/ContractMgt/AgreementChangeMgtApp03/:id',
    label: '협약변경관리 신청 사업비',
    element: (
      <Loader
        route={middleware({
          label: 'biz',
          layout: 'basic',
          element: React.lazy(
            () => import('~/pages/biz/ContractMgt/AgreementChangeMgtApp03')
          ),
        },['auth'])}
      />
    ),
  },
  {
    path: '/biz/ContractMgt/AgreementChangeMgtApp04/:id',
    label: '협약변경관리 신청 비목별사업비',
    element: (
      <Loader
        route={middleware({
          label: 'biz',
          layout: 'basic',
          element: React.lazy(
            () => import('~/pages/biz/ContractMgt/AgreementChangeMgtApp04')
          ),
        },['auth'])}
      />
    ),
  },
  {
    path: '/biz/ContractMgt/AgreementChangeMgtApp05/:id',
    label: '협약변경관리 신청 신청자정보',
    element: (
      <Loader
        route={middleware({
          label: 'biz',
          layout: 'basic',
          element: React.lazy(
            () => import('~/pages/biz/ContractMgt/AgreementChangeMgtApp05')
          ),
        },['auth'])}
      />
    ),
  },
  {
    path: '/biz/ContractMgt/AgreementChangeMgtApp06/:id',
    label: '협약변경관리 신청 과제책임자',
    element: (
      <Loader
        route={middleware({
          label: 'biz',
          layout: 'basic',
          element: React.lazy(
            () => import('~/pages/biz/ContractMgt/AgreementChangeMgtApp06')
          ),
        },['auth'])}
      />
    ),
  },

  {
    path: '/biz/EvaluationMgt/ObjectionDetail/:id',
    label: '협약변경관리 상세',
    element: (
      <Loader
        route={middleware({
          label: 'biz',
          layout: 'basic',
          element: React.lazy(
            () => import('~/pages//biz/EvaluationMgt/View/ObjectionDetail')
          ),
        },['auth'])}
      />
    ),
  },
  {
    path: '/biz/TaskManagement/PerformanceMgtDetail/:id',
    label: '성과상세페이지',
    element: (
      <Loader
        route={middleware({
          label: 'biz',
          layout: 'basic',
          element: React.lazy(
            () => import('~/pages/biz/TaskManagement/PerformanceMgtDetail')
          ),
        },['auth'])}
      />
    ),
  },
  {
    path: '/biz/TaskManagement/ReportSubmissionDetail/:id',
    label: '보고서 제출',
    element: (
      <Loader
        route={middleware({
          label: 'biz',
          layout: 'basic',
          element: React.lazy(
            () =>
              import('~/pages/biz/TaskManagement/View/ReportSubmissionDetail')
          ),
        },['auth'])}
      />
    ),
  },
  {
    path: '/biz/TaskManagement/ParticipatInSfSy/:id',
    label: '설문 상세',
    element: (
      <Loader
        route={middleware({
          label: 'biz',
          layout: 'basic',
          element: React.lazy(
            () =>
              import('~/pages/biz/TaskManagement/ParticipatInSfSyDetail')
          ),
        },['auth'])}
      />
    ),
  },
].map((route: Partial<RouteType>) => ({
  ...route,
  layout: 'space',
})) as RouteType[];

// 공통 라우트
export const UtilityRoutes: RouteType[] = [
  {
    path: 'factor',
    label: '비밀번호 확인',
    element: (
      <Loader
        route={{
          label: 'factor',
          layout: 'basic',
          element: React.lazy(() => import('~/pages/Factor')),
        }}
      />
    ),
  },
  {
    path: 'MyPage/MemberInfoMmt/BusinessConversionEnter',
    label: '사업자 전환 입력폼',
    element: (
      <Loader
        route={middleware(
          {
            label: 'BusinessConversionEnter',
            layout: 'basic',
            element: React.lazy(
              () =>
                import('~/pages/MyPage/MemberInfoMmt/BusinessConversionEnter')
            ),
          },
          ['auth']
        )}
      />
    ),
  },
  {
    path: 'MyPage/MemberInfoMmt/BusinessConversionFinish',
    label: '사업자 전환 완료',
    element: (
      <Loader
        route={middleware(
          {
            label: 'BusinessConversionFinish',
            layout: 'basic',
            element: React.lazy(
              () =>
                import('~/pages/MyPage/MemberInfoMmt/BusinessConversionFinish')
            ),
          },
          ['auth']
        )}
      />
    ),
  },
  {
    path: 'MyPage/MemberInfoMmt/BusinessConversionSpool',
    label: '사업자 전환 실패',
    element: (
      <Loader
        route={middleware(
          {
            label: 'BusinessConversionSpool',
            layout: 'basic',
            element: React.lazy(
              () =>
                import('~/pages/MyPage/MemberInfoMmt/BusinessConversionSpool')
            ),
          },
          ['auth']
        )}
      />
    ),
  },
  {
    path: 'MyPage/MemberInfoMmt/MemberInfoOut',
    label: '회원탈퇴',
    element: (
      <Loader
        route={middleware(
          {
            label: 'MemberInfoOut',
            layout: 'basic',
            element: React.lazy(
              () => import('~/pages/MyPage/MemberInfoMmt/MemberInfoOut')
            ),
          },
          ['auth']
        )}
      />
    ),
  },
  {
    path: 'information',
    label: '개인정보처리방침',
    element: (
      <Loader
        route={{
          label: 'sign',
          layout: 'space',
          element: React.lazy(() => import('~/pages/Information')),
        }}
      />
    ),
  },
  {
    path: 'TosContent',
    label: '이용약관',
    element: (
      <Loader
        route={{
          label: 'sign',
          layout: 'space',
          element: React.lazy(() => import('~/pages/TosContent')),
        }}
      />
    ),
  },
].map((route: Partial<RouteType>) => ({
  ...route,
  layout: 'space',
})) as RouteType[];