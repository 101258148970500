import React, {CSSProperties, useLayoutEffect, useState} from 'react'
import Box from '@mui/material/Box';
import {
  CircularProgress, Container,
  FormControlLabel,
  FormGroup,
  InputLabel,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tab,
  TextField,
} from '@mui/material';
import {Color} from '../components/StyleUtils';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import styled from '@emotion/styled';
import {TabContext, TabList} from "@mui/lab";
import MUIPagination from "@mui/material/Pagination";
import {DefaultCheckBoxProps} from "../components/ButtonComponents";
import TableContainer from "@mui/material/TableContainer";
import {CheckboxStyle, TitleCheckboxStyle} from "../components/TableComponents";
import {Body2, Body3, Body4} from "../components/TextComponents";

export const VerticalInterval: React.FC<{
  size: number | string;
}> = (props) => <div style={{marginTop: props.size}}/>;

export const HorizontalInterval: React.FC<{
  size: number | string;
}> = (props) => <div style={{marginLeft: props.size}}/>;

export const BlockContents: React.FC<{
  children?: any,
  title?: string;
  title_sub?: string;
  maxHeight?: string;
  rightContent?: React.ReactNode;
}> = (props) => {
  return (
    <Box>
      <Stack
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        marginRight={'30px'}
      >
        {props.title && (
          <h2 style={{margin: '60px 0 20px 0', fontSize: '1.625rem'}}>
            {props.title}
          </h2>
        )}
        {props.title_sub && (
          <div
            style={{
              margin: '16px 30px',
              fontSize: '1.25rem',
              fontWeight: 'bold',
            }}
          >
            {props.title_sub}
          </div>
        )}
        {props.rightContent}
      </Stack>
      <Box
        style={{
          maxHeight: props.maxHeight || '300px',
          overflow: 'auto',
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export const CustomTabs: React.FC<{
  children?: any;
  tabs: string[];
  onClick?: (newValue: string) => void;
}> = (props) => {
  const [value, setValue] = useState<string>(props.tabs[0]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (props.onClick) props.onClick(newValue);

    setValue(newValue);
  };

  return (
    <Box sx={{width: '100%', typography: 'body1'}}>
      <TabContext value={value}>
        <Box sx={{borderBottom: 1, borderColor: Color.primary}}>
          <TabList onChange={handleChange} variant={'scrollable'}>
            {props.tabs.map((m, i) => {
              return <TabContainer key={i} label={m} value={m}/>;
            })}
          </TabList>
        </Box>
      </TabContext>
    </Box>
  );
}

const TabContainer = styled(Tab)({
  // border: '1px solid #a7aec9',
  background: Color.line,
  color: Color.warm_gray,
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,
  marginRight: '1px',
  fontSize: '16px',

  '&.Mui-selected': {
    background: Color.white,
    color: Color.black,
    '&.tabBlack': {
      background: Color.black,
      color: Color.white,
    }
  },
});

export const Pagination: React.FC<{
  curPage: number;
  totalPage: number;
  rowsPerPage: number;
  handleChangeRowsPerPage: (event: any) => void;
  handleChangePage: (event: unknown, newPage: number) => void;
  hideRowPerPage?: boolean;
  style?: React.CSSProperties;
}> = (props) => {

  return <Box style={props.style}>
    {
      !props.hideRowPerPage && <Box
      sx={{display: 'flex', color: '#666'}}
      justifyContent={'right'}
      alignItems={'center'}
    >
      <InputLabel
        variant='standard'
        htmlFor='uncontrolled-native'
        sx={{pr: 2}}
      >
        Page {props.curPage + 1} / {props.totalPage}
      </InputLabel>
      <FormControl variant='standard'>
        <SelectStyle
          className='select'
          value={props.rowsPerPage}
          onChange={props.handleChangeRowsPerPage}
        >
          {[5, 10, 15].map((v: any) => (
            <MenuItem key={v} value={v}>
              {v}개씩
            </MenuItem>
          ))}
        </SelectStyle>
      </FormControl>
    </Box>
    }
    <MUIPagination
      color='primary'
      count={props.totalPage}
      showFirstButton
      showLastButton
      page={props.curPage + 1}
      onChange={props.handleChangePage}
      style={{display: 'flex', justifyContent: 'center'}}
    />
  </Box>
}

export const LnbListItem: React.FC<{
  id: string
  label: string
  depth: number
  url: string
  isChild: boolean
  active: boolean
  icon?: React.ReactNode
  onClick?: (id: string, url: string, isChild: boolean) => void
}> = props => {
  const padding = props.depth * 20;
  const className = props.active ? 'active' : undefined

  return <ListItemButton
    className={className}
    sx={{pl: padding.toString() + 'px'}}
    onClick={() => {
      if (props.onClick) props.onClick(props.id, props.url, props.isChild)
    }}>
    {
      props.icon && <ListItemIcon>
        {props.icon}
        </ListItemIcon>
    }
    <ListItemText primary={
      props.label
    }/>
  </ListItemButton>
}

export const SimpleTextField: React.FC<{
  required?: boolean
  label?: string
  disabled?: boolean
  defaultLabel?: string
  multiline?: boolean
  row?: number
  placeholder?: string
  wordCount?: number | any
  fullWidth?: boolean
  title?: boolean
  onChange?: (text: string) => void
}> = props => {
  const [value, setValue] = useState<string>('');
  useLayoutEffect(() => {
    if (!!props.defaultLabel) {
      setValue(props.defaultLabel)
    }
  }, [props.defaultLabel])

  return <Box height={'180px'} overflow={'hidden'}>
    <FormControl fullWidth={props.fullWidth}>
      {props.title ?
        <TextFieldTitleContainer
          disabled={props.disabled}
          //label={props.label || "내용"}
          required={props.required}
          value={value}
          size={"small"} sx={{paddingTop: '3px'}}
          placeholder={props.placeholder}
          multiline={props.multiline}
          rows={props.row}
          onChange={(e) => {
            if (props.onChange)
              props.onChange(e.target.value)
            setValue(e.target.value)
            if (props.wordCount <= value.length) {
              const idx = (props.wordCount - value.length) < 0 ? props.wordCount - value.length : -1;
              setValue(value.slice(0, idx));
              alert("제한글자를 지켜주세요")
            }
          }}/>
        :
        <TextFieldContainer
          disabled={props.disabled}

          //label={props.label || "내용"}
          required={props.required}
          value={value}
          size={"small"} sx={{paddingTop: '3px', whiteSpace:'pre-wrap',
          '*::-webkit-scrollbar': {
          width: '8px'
        },
          '*::-webkit-scrollbar-thumb': {
          backgroundColor: '#d7dae6',
          borderRadius: '10px'
        },
          '*::-webkit-scrollbar-track': {
          backgroundColor: '#fff',
          borderRadius: '10px'
        }}}
          placeholder={props.placeholder}
          multiline={props.multiline}
          rows={props.row}
          onChange={(e) => {
            if (props.onChange)
              props.onChange(e.target.value)
            setValue(e.target.value)
            if (props.wordCount <= value.length) {
              const idx = (props.wordCount - value.length) < 0 ? props.wordCount - value.length : -1;
              setValue(value.slice(0, idx));
              alert("제한글자를 지켜주세요")
            }
          }}/>}
    </FormControl>
    {props.wordCount && <WordCount curWord={value.length} maxWord={props.wordCount}/>}
  </Box>
}

export const LoadingProgress = () => {
  return <Box display={'flex'} height={'100%'} alignItems={'center'} justifyContent={'center'}>
    <CircularProgress/>
  </Box>
}

export const WordCount: React.FC<{
  curWord: number
  maxWord: string | number
  style?: React.CSSProperties
}> = props => {
  return <Box display={"flex"} justifyContent={"right"}
              style={{...props.style}}>
    <Body3 color={Color.brownish_gray} style={{height: '19px'}}>
      {props.curWord + "/" + props.maxWord}</Body3>
  </Box>
}

export const SubContents: React.FC<{
  title: string
  contentCount?: number
  borderTop?: string
  hideDivision?: boolean
  subTitle?: string;
  maxHeight?: string
  overflow?: boolean
  required?: boolean
  marginBottom?: string
  rightContent?: React.ReactNode;
  style?: React.CSSProperties;
}> = (props) => {
  return (
    <Box sx={{width: "100%", borderTop: props.borderTop || "none"}}>
      <Stack
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        marginRight={'30px'}
        marginBottom={props.marginBottom}>
        {props.title && (
          <Body2 weight={500} style={{paddingLeft: "0px", display: "flex", ...props.style}}>
            {props.title}{props.contentCount && <h5 style={{margin: "5px"}}>{props.contentCount}건</h5>}
            {props.subTitle &&
                <Body4 style={{paddingTop: '4px', marginLeft: '5px', fontSize: '14px'}} color={Color.warm_gray}>{props.subTitle}</Body4>}
            {props.required && <Body4 color={Color.topaz} weight={500} style={{paddingLeft: '3px'}}>*</Body4>}
          </Body2>
        )}
        {props.rightContent}
      </Stack>
      <Box
        sx={{
          maxHeight: props.maxHeight || '300px',
          overflow: props.overflow ? 'hidden' : 'auto',
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
}

export const ConsentLayout: React.FC<{
  checkbox: DefaultCheckBoxProps;
  onClick?: (selected: string[]) => void;
  isMobile?: boolean
  isEnterprise?: boolean
  onChange?: any
  onChange1?: any
  onChange2?: any
  checked?: boolean
  checked1?: boolean
  checked2?: boolean
  row?: boolean
  justifyContent?: 'center' | 'right' | 'left'
  style?: CSSProperties
}> = (props) => {

  return <>
    <FormControl>
      <FormGroup
        row={props.row}
        sx={{justifyContent: props.justifyContent, ...props.style, marginBottom: '20px'}}>
        <FormControlLabel
          label={<Body2 weight={500}
                        style={{fontSize: props.isMobile ? '14px' : '18px'}}>{props.checkbox.label}</Body2>}
          control={<TitleCheckboxStyle
            onChange={props.onChange} checked={props.checked}/>}
        />
      </FormGroup>
    </FormControl>
    <TableContainer
      sx={{borderTop: "1px solid #000000", width: "100%", overflow: "hidden", borderColor: '#pinkish-grey'}}>
      <FormControl fullWidth>
        <FormGroup
          row={props.row}
          sx={{justifyContent: props.justifyContent, ...props.style}}>
          <div>
            <div style={{display: 'flex'}}>
              <FormControlLabel
                control={
                  <CheckboxStyle
                    onChange={props.onChange1} checked={props.checked1}
                    sx={{marginLeft: "3px", marginTop: '20px'}}
                  />}
                sx={{alignItems: 'end'}}
                label={<><Body3>개인정보 수집·이용에 관한 사항</Body3> <Body3 weight={500} style={{marginLeft: '4px'}}
                                                                 color={Color.azul}>(필수)</Body3></>}
              />
            </div>
            <div style={{
              minHeight: "120px",
              border: "1px solid #d7dae6",
              borderRadius: "5px",
              padding: "16px 20px 16px 16px",
              overflow: "auto",
              width: "100%",
              marginTop: '20px'
            }}>
              <Body3 lineClamp={1} weight={500} style={{lineHeight: '28px'}}>수집·이용 목적</Body3>
              <Body3 style={{whiteSpace: 'pre-wrap', lineHeight: '28px'}}>-서비스 제공에 관한 인공지능산업융합사업단 AI통합지원서비스플랫폼에서 제공하는 모든
                서비스에 활용 <br/>
                -회원제 서비스 이용에 따른 본인확인, 개인 식별, 불량회원의 부정 이용 방지와 비인가 사용 방지, 가입의사 확인처리 <br/>
                -AI통합지원서비스 관련 회원관리</Body3>
              <br/> <br/>
              <Body3 lineClamp={1} weight={500} style={{lineHeight: '28px'}}>수집·이용 항목</Body3>
              <Body3 style={{whiteSpace: 'pre-wrap', lineHeight: '28px'}}>{props.isEnterprise ? '-ID, 비밀번호, 담당자명, 휴대폰번호, 이메일, 사업자명, 사업자번호' : '-ID, 비밀번호, 이름, 생년월일, 성별, 휴대폰번호, 이메일'}</Body3>
              <br/> <br/>
              <Body3 lineClamp={1} weight={500} style={{lineHeight: '28px'}}>보유·이용 기간</Body3>
              <Body3 style={{whiteSpace: 'pre-wrap', lineHeight: '28px'}}>-회원탈퇴 후 7일까지</Body3>
              <br/> <br/>
              <Body3 style={{whiteSpace: 'pre-wrap', lineHeight: '28px'}}>
                ※ 이용자는 위의 개인정보 수집·이용에 대한 동의를 거부할 권리가 있습니다. <br/>
                다만, 회원가입 시 수집하는 필수항목에 수집 및 이용 동의를 거부할 경우, 회원가입이 불가능합니다.<br/>
                ※ 만 14세 미만 회원은 법정대리인의 동의를 받은 경우에만 회원가입이 가능합니다.</Body3>
            </div>
            <div style={{display: 'flex'}}>
              <FormControlLabel
                control={
                  <CheckboxStyle
                    onChange={props.onChange2} checked={props.checked2}
                    sx={{marginLeft: "3px", marginTop: '20px'}}
                  />}
                sx={{alignItems: 'end'}}
                label={<><Body3>개인정보 수집·이용에 관한 사항</Body3> <Body3 weight={500} style={{marginLeft: '4px'}}
                                                                 color={Color.azul}>(선택)</Body3></>}
              />
            </div>
            <div style={{
              minHeight: "120px",
              border: "1px solid #d7dae6",
              borderRadius: "5px",
              padding: "16px 20px 16px 16px",
              overflow: "auto",
              width: "100%",
              marginTop: '20px'
            }}>
              <Body3 lineClamp={1} weight={500} style={{lineHeight: '28px'}}>수집·이용 목적</Body3>
              <Body3 style={{whiteSpace: 'pre-wrap', lineHeight: '28px'}}>-서비스 제공에 관한 인공지능산업융합사업단 AI통합지원서비스플랫폼에서 제공하는 모든 서비스에 활용 <br/>
                -회원제 서비스 이용에 따른 본인확인, 개인 식별, 불량회원의 부정 이용 방지와 비인가 사용 방지, 가입의사 확인처리<br/>
                -AI통합지원서비스 관련 회원관리</Body3>
              <br/> <br/>
              <Body3 lineClamp={1} weight={500} style={{lineHeight: '28px'}}>수집·이용 항목</Body3>
              <Body3 style={{whiteSpace: 'pre-wrap', lineHeight: '28px'}}>{props.isEnterprise ? '-소속부서, 직위, 전화번호, 팩스번호, 마케팅정보수신여부, 법인등록번호' : '-수요조사 및 만족도조사, 성과조사 등'}</Body3>
              <br/> <br/>
              <Body3 lineClamp={1} weight={500} style={{lineHeight: '28px'}}>보유·이용 기간</Body3>
              <Body3 style={{whiteSpace: 'pre-wrap', lineHeight: '28px'}}>-회원탈퇴 후 7일까지</Body3>
              <br/> <br/>
              <Body3 style={{whiteSpace: 'pre-wrap', lineHeight: '28px'}}>※ 이용자는 위의 개인정보 수집·이용에 대한 동의를 거부하셔도, 회원가입이 가능합니다.<br/>
                다만, 선택항목 동의의 거부에 따른 일부서비스 제공에 제한을 받을 수 있습니다.</Body3>
            </div>
          </div>
        </FormGroup>
      </FormControl>
    </TableContainer>
  </>
}

export const NoServerData: React.FC<{
  message?: string
  style?: CSSProperties
}> = props => {
  return <NoDataBox style={props.style}>
    <Box className="icon_errBox">
    </Box>
    <img src="/tsp/images/common/icon_error_gray.png" alt={'게시물이 없습니다.'}/><br/>
    {props.message || '게시물이 없습니다.'}
  </NoDataBox>
}


const SelectStyle = styled(Select)`
  border: 1px solid #d7dae6;
  width: 121px;
  height: 40px;
  padding: 0;

  .MuiSelect-select {
    padding: 0 20px 0 16px;
    height: 40px;
    line-height: 40px;
  }

  .MuiSvgIcon-root {
    margin-right: 10px;
  }
`

const TextFieldContainer = styled(TextField)`
  .MuiInputBase-root {
    height: 150px;
    align-items: baseline;
  }
`

const TextFieldTitleContainer = styled(TextField)`
  .MuiInputBase-root {
    height: 40px;
    align-items: baseline;
  }
`

const NoDataBox = styled(Container)`
  padding: 60px 30px;
  width: 100%;
  text-align: center;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.72px;
  color: #222;
  font-weight: 400;
  font-family: "Noto Sans CJK KR", "Roboto";
  //border-top: 1px solid #1f2437;
  border-bottom: 1px solid #e0e0e0;
  .icon_errBox{
    margin-bottom: 30px;
    line-height: 1;
  }
  @media (min-width: 320px) and (max-width: 768px) {
    padding: 48px 15px;
    letter-spacing: -0.64px;
    font-size: 16px;
    .icon_errBox{
      margin-bottom: 20px;
    }
  }
`

